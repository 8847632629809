import React, { Component } from 'react';
import Container from '../components/Container';
import SectionTitle from '../components/SectionTitle';
import WTB from '../components/WTB';
import '../css/Product.css';
import {withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";


import products from '../data/products.json';

import DW6010 from '../images/DW6010.png';
import DW9230 from '../images/DW9230.png';
import DW5110 from '../images/DW5110.png';
import DG8960G0 from '../images/DG8960G0.png';

class Product extends Component {
  constructor(props){
		super(props)
		this.state = {
			product: {},
      gallery: [],
      benefits: [],
      current: null,
		}
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      current: nextProps.location.state.product
    }, () => {
      products.map((product) => {
        if (product.sku === this.state.current) {
          this.setState({
            product: product,
            gallery: product.gallery,
            benefits: product.benefits
          });
        }
      });
    });
  }

	componentDidMount() {
    const current = this.props.location.pathname.split('/').pop().trim();

		// Make a request for a user with a given ID
    this.setState({
      current: this.props.location.state ? this.props.location.state.product : current
    }, () => {
      products.map((product) => {
        if (product.sku === this.state.current) {
          this.setState({
            product: product,
            gallery: product.gallery,
            benefits: product.benefits
          });
        }
      });
    })
  }

  render() {
    const product = this.state.product;
    return (
      <div className="Product">
        <ProductIntro product={product} gallery={this.state.gallery} />
        <ProductBenefits product={product} benefits={this.state.benefits} />
        <ProductDetails product={product} />
      </div>
    );
  }
}

class ProductIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      gallery: [],
      imageToShow: props.gallery[0]
    }
  }

  componentWillReceiveProps(nextProps) {
    this.swapImage(nextProps.gallery[0]);
  }

  swapImage(image, e) {
    this.setState(prevState => ({
      imageToShow: image
    }));
  }

  render() {
    const product = this.props.product;
    const gallery = this.props.gallery.map((image) =>
      <img alt="" className={"product-image " + ((this.state.imageToShow === image) ? "show" : "hide") }  key={image} src={image} />
    );

    const thumbsGallery = this.props.gallery.map((image) =>
      <img alt="" onClick={(e) => this.swapImage(image)} key={image} src={image} />
    );
    return(
      <div className="ProductSection">
      <Helmet title={`Rowenta | ${this.props.product.sku}`}></Helmet>
        <section className="ProductBlock">
          <Container>
            <div className="ProductBlock-left">
              <div className="ProductBlock-left-main">
                {gallery}
              </div>
              <div className="ProductBlock-left-thumbnail">
                {thumbsGallery}
              </div>
            </div>
            <div className="ProductBlock-right">
              <h2>{product.title}</h2>
              <h3>{product.subtitle}</h3>
							<p dangerouslySetInnerHTML={{ __html: product.shortDescription }} />
              <p className="ref">Reference: {product.sku}</p>
              <WTB data={product} />
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

const ProductDetails = (props) => {
  const product = props.product;
  return(
    <section className="ProductDetails">
      <Container>
        <div className="DetailsSection-1" id="description">
          <div className="DetailsSection-1-inner">
						<h2 dangerouslySetInnerHTML={{ __html: product.editorialTitle }} />
            <hr />
						<h3 dangerouslySetInnerHTML={{ __html: product.editorialDescription }} />
            <hr />
						<p dangerouslySetInnerHTML={{ __html: product.editorialDescriptionParagrah }} />
          </div>
        </div>

        <div className="DetailsSection-2" id="features" style={{ display: 'none' }} >
          <div className="DetailsSection-2-inner">
            <h3 className="SectionTitleSimple"><i>Technical specifications</i></h3>
            <div className="FixedPartTable" >
              <table>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <div className="picture">
                        <a href="/LINEN-CARE/STEAM-IRONS/ECO-INTELLIGENCE-STEAM-IRON-DW6010/p/1110030631"><img src={DW6010} alt="Rowenta Eco Focus DW6010 Steam Iron" title="Rowenta Eco Focus DW6010 Steam Iron"/></a>
                        <div className="name">
                          <a href="/LINEN-CARE/STEAM-IRONS/ECO-INTELLIGENCE-STEAM-IRON-DW6010/p/1110030631" title="Rowenta Eco Focus DW6010 Steam Iron">Rowenta Eco Focus DW6010 Steam Iron</a>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="picture">
                        <a href=""><img src={DW5110} alt="Rowenta Focus DW5110 Steam Iron" title="Rowenta Focus DW5110 Steam Iron"/></a>
                        <div className="name">
                          <a href="" title="Rowenta Focus DW5110 Steam Iron">Rowenta Focus DW5110 Steam Iron</a>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="picture">
                        <a href=""><img src={DW9230} alt="Rowenta Steam Force DW9230 Steam Iron" title="Rowenta Steam Force DW9230 Steam Iron"/></a>
                        <div className="name">
                          <a href="" title="Rowenta Steam Force DW9230 Steam Iron">Rowenta Steam Force DW9230 Steam Iron</a>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="picture">
                        <a href=""><img src={DG8960G0} alt="Rowenta Silence DG8962 Steam Generator Iron" title="Rowenta Silence DG8962 Steam Generator Iron"/></a>
                        <div className="name">
                          <a href="" title="Rowenta Silence DG8962 Steam Generator Iron">Rowenta Silence DG8962 Steam Generator Iron</a>
                        </div>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="ScrollableTable">
              <table>
                <tbody>
                  <tr className="ressemblant">
                    <td className="info" >Anti scale function</td>
                    <td >Integrated  anti-scale and Anti-scale setting
                      &nbsp;<br/>
                    </td>
                    <td  >Integrated  anti-scale and Anti-scale setting
                      &nbsp;<br/>
                    </td>
                    <td  >Integrated  anti-scale and Anti-scale setting
                      &nbsp;<br/>
                    </td>
                    <td  >Integrated  anti-scale and Anti-scale setting
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Soleplate Technology</td>
                    <td >MICROSTEAM400 3De
                      &nbsp;<br/>
                    </td>
                    <td  >MICROSTEAM400 Profile
                      &nbsp;<br/>
                    </td>
                    <td  >Stainless steel
                      &nbsp;<br/>
                    </td>
                    <td  >MICROSTEAM400
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Glideability of soleplate</td>
                    <td >****
                      &nbsp;<br/>
                    </td>
                    <td  >****
                      &nbsp;<br/>
                    </td>
                    <td  >****
                      &nbsp;<br/>
                    </td>
                    <td  >****
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Durability of soleplate / scratch resistance</td>
                    <td >****
                      &nbsp;<br/>
                    </td>
                    <td  >****
                      &nbsp;<br/>
                    </td>
                    <td  >*****
                      &nbsp;<br/>
                    </td>
                    <td  >****
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Precision Tip</td>
                    <td ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Concentrated Steam boost at tip of soleplate</td>
                    <td ><span className="not">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Continuous steam diffusion</td>
                    <td >Tip, side and heart
                      &nbsp;<br/>
                    </td>
                    <td  >Tip, side and heart
                      &nbsp;<br/>
                    </td>
                    <td  >Tip, side and heart
                      &nbsp;<br/>
                    </td>
                    <td  >Tip, side and heart
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Stable rest hill</td>
                    <td ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Water tank capacity</td>
                    <td >300
                      &nbsp;ml<br/>
                    </td>
                    <td  >370
                      &nbsp;ml<br/>
                    </td>
                    <td  >350
                      &nbsp;ml<br/>
                    </td>
                    <td  >370
                      &nbsp;ml<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Water filling hole</td>
                    <td >Medium
                      &nbsp;<br/>
                    </td>
                    <td  >Medium
                      &nbsp;<br/>
                    </td>
                    <td  >Extra Large
                      &nbsp;<br/>
                    </td>
                    <td  >Medium
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Anti-drip</td>
                    <td ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Auto-Off</td>
                    <td ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="is">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Eco Mode</td>
                    <td >Eco Intelligence
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="not">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="not">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                    <td  ><span className="not">&nbsp;</span>
                      &nbsp;<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Power cord length</td>
                    <td >2.5
                      &nbsp;m<br/>
                    </td>
                    <td  >2.4
                      &nbsp;m<br/>
                    </td>
                    <td  >2,5
                      &nbsp;m<br/>
                    </td>
                    <td  >2.4
                      &nbsp;m<br/>
                    </td>
                  </tr>
                  <tr className="ressemblant">
                    <td className="info" >Power cord length (ft)</td>
                    <td >7.9
                      &nbsp;<br/>
                    </td>
                    <td  >7.9
                      &nbsp;<br/>
                    </td>
                    <td  >7,9
                      &nbsp;<br/>
                    </td>
                    <td  >7.9
                      &nbsp;<br/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>

      <div className="DetailsSection-3" id="documentation" style={{ display: 'none' }} >
        <Container>
          <a className="Doc" href="/warranty" title="Warranty information">
            <div className="DocIcon">
              <img src="https://www.rowentausa.com/medias/garantie.png?context=bWFzdGVyfGltYWdlc3w5MzR8aW1hZ2UvcG5nfGltYWdlcy9oZjYvaGFlLzg5MTU2ODg0ODg5OTAucG5nfDZmN2I1YjdmY2M0MjRhOThjNWUwNTljMTZhMDIyMGRhYzliOWIzMDcwYmE4Yzk5NGRmM2Q3NjdlZDc4M2RmYzM" alt=""/>
            </div>
            <div className="DocTitle">
              <hr />
              <p>Warranty information</p>
              <hr />
            </div>
          </a>
        </Container>
      </div>
    </section>
  );
}

const ProductBenefits = (props) => {
  const product = props.product;
  const benefits = props.benefits.map((benefit) =>
    <div className="Benefit" key={benefit.title}>
      <div className="Benefit-img-wrapper">
        <img alt="" src={benefit.img} />
      </div>
        <div className="BenefitCaption">
					<p dangerouslySetInnerHTML={{ __html: benefit.title }} />
					<p dangerouslySetInnerHTML={{ __html: benefit.paragraph }} />
        </div>
    </div>
  );

  return(
    <section className="Benefits">
      <Container>
        <SectionTitle topTitle="Product" bottomTitle="Benefits" color="#a4b0b8"/>
        {benefits}
      </Container>
    </section>
  );
}

const ProductBoxesThree = () => {
  return(
    <div>ProductBoxesThree</div>
  );
}

export default withRouter(Product);