import React from "react";

import "../css/buyOnlineButton.css";

const BuyOnlineButton = ({ href }) => {
  console.log(href);
  return (
    <>
      <a class="buyOnlineButton" href={href} title="Buy Online" rel="noopener noreferrer" target="_blank">
        Buy Online
      </a>
    </>
  );
};

export default BuyOnlineButton;
