import React, { Fragment, useState } from 'react';

import '../css/FacialBrushRadianceFeatures.css'
import s41 from '../images/facial-images/brush-performance-visual.jpg';

const FacialBrushRadianceFeatures = () => {
	const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;
	const Product = ({ features, dots }) => {
		const [active, setActive] = useState(1);		

		return (
			<div className="radiance-slide4-content">
				<div className="radiance-slide4-content-left">
						<img src={s41} />
						{dots.map(d => {
							return (
								<button
									tabIndex={0}
									key={d.id}
									className={["circle-button", active === d.id ? "active" : ""].join(" ")}
									onClick={() => {setActive(d.id);}}
									style={{
										left: d.left,
										right: d.right,
										top: d.top,
										bottom: d.bottom,
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">  <path d="M12.9 23.6h-1.7V12.8H.4v-1.5h10.8V.4h1.7v10.8h10.7v1.5H12.9v10.9z"></path></svg>
								</button>
							);
						})}
				</div>

				<div className="radiance-slide4-content-right">
					<div className="features">
						{features.map(f => {
							return (
								<div
									tabIndex="-1"
									key={f.id}
									className={["feature", active === f.id ? "active" : ""].join(" ")}>	
										<div className="featureDesc">{f.desc}</div>
								</div>
							);
						})}
					</div>
				</div>
				

				
			</div >
		);
	}

	return (
		<section >
					<Product					
						dots={[
							{ id: 1, bottom: "34px", right: "90px" },
							{ id: 2, right: "68px", top: `${isMobile ? "118px" : "137px"}` },
							{ id: 3, left: `${isMobile ? "90px" : "140px"}`, bottom: `${isMobile ? "6px" : "20px"}` },
							{ id: 4, right: `${isMobile ? "150px" : "173px"}`, top: "0" },
							{ id: 5, left: `${isMobile ? "74px" : "117px"}`, top: "37px" },
						]}
						features={[
							{
								id: 1,
								desc: <Fragment><p><strong>Blue LED lights sanitising zone</strong>. Blue LEDs are known for their purifying properties. Clean and dry carefully your brush head after each use to ensure its long-lasting performance day after day</p></Fragment>,
							},
							{
								id: 2,
								desc: <Fragment><p><strong>Soft Nylon brush</strong> to remove make-up of sensitive skin</p></Fragment>,							
							},
							{
								id: 3,
								desc: <Fragment><p><strong>Induction charging base</strong> for a quick and simple charge</p></Fragment>,								
							},
							{
								id: 4,
								desc: <Fragment><p>Lithium battery up to <strong>90 uses after charge</strong></p></Fragment>,								
							},
							{
								id: 5,
								desc: <Fragment><p><strong>Cleansing Silicone brush</strong> that massages your delicate skin</p></Fragment>,								
							},
							
						]}
					/>


		</section>
	);
}

export default FacialBrushRadianceFeatures;