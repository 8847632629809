import React, { useState } from 'react';
import FAQTab1 from './FAQTab1';
import FAQTab2 from './FAQTab2';
import FAQTab3 from './FAQTab3';

import FAQTab1Image from '../images/facial-images/brush-faq-visual.jpg';
import FAQTab2Image from '../images/facial-images/facial-massager-faq-visual.jpg';
import FAQTab3Image from '../images/facial-images/eye-massager-faq-visual.jpg';


const FacialEye = () => {
    const [product, setProduct] = useState("Facial");

    return (
		<section id="faqFacialSection">
            <h2>Your questions &amp; our answers</h2>
            <div className="faqFacialSection-content">
                <div className='toggle'>
                    <a
                        onClick={() => setProduct("Facial")}
                        className={product === "Facial" ? "activetab" : ""}>
                            <div className="facialButton">
                                <img src={FAQTab1Image} />
                                Facial Brush
                            </div>
                    </a>
                    <a
                        onClick={() => setProduct("Youth")}
                        className={product === "Youth" ? "activetab" : ""}>
                            <div className="youthButton">
                                <img src={FAQTab2Image} />
                                Youth Facial<br />Massager
                            </div>
                    </a>
                    <a
                        onClick={() => setProduct("Eye")}
                        className={product === "Eye" ? "activetab" : ""}>
                            <div className="eyeButton">
                                <img src={FAQTab3Image} />
                                Eye Massager
                            </div>
                    </a>
                </div>
                <div className='facialTab' style={{display: product === "Facial" ? "flex" : "none"}} >				
                    <FAQTab1 />
                </div>
                <div className='youthTab' style={{display: product === "Youth" ? "flex" : "none"}} >				
                    <FAQTab2 />
                </div>
                <div className='eyeTab' style={{display: product === "Eye" ? "flex" : "none"}} >				
                    <FAQTab3 />
                </div>
            </div>
            


		</section>
	);
}

export default FacialEye;