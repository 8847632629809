import React, { useState } from 'react';
import FAQFacial from './FAQFacial';
import FAQEye from './FAQEye';

import FAQbrush from '../images/noflaws-faq-brush.jpg';
import FAQeye from '../images/noflaws-faq-eyemassager.jpg';

const FacialEye = () => {
    const [product, setProduct] = useState("Facial");

    return (
		<section id="faqSection">
            <h2>Your questions &amp; our answers</h2>
            <div className="faqSection-content">
                <div className='toggle'>
                    <a
                        onClick={() => setProduct("Facial")}
                        className={product === "Facial" ? "activetab" : ""}>
                            <div className="facialButton">
                                <img src={FAQbrush} />
                                Facial Brush
                            </div>
                    </a>
                    <a
                        onClick={() => setProduct("Eye")}
                        className={product === "Eye" ? "activetab" : ""}>
                            <div className="eyeButton">
                                <img src={FAQeye} />
                                Eye Massager
                            </div>
                    </a>
                </div>
                <div className='facialTab' style={{display: product === "Facial" ? "flex" : "none"}} >				
                    <FAQFacial />
                </div>
                <div className='eyeTab' style={{display: product === "Eye" ? "flex" : "none"}} >				
                    <FAQEye />
                </div>
            </div>
            


		</section>
	);
}

export default FacialEye;