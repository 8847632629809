import React, { Fragment, useState } from 'react';

import '../css/FacialBrushRadianceFeatures.css'
import s41 from '../images/facial-images/eye-massager-performance-visual.png';

const FacialBrushRadianceFeatures = () => {
	const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;
	const Product = ({ features, dots }) => {
		const [active, setActive] = useState(3);		

		return (
			<div className="radiance-slide4-content">
				<div className="radiance-slide4-content-left">
						<img src={s41} />
						{dots.map(d => {
							return (
								<button
									tabIndex={0}
									key={d.id}
									className={["circle-button", active === d.id ? "active" : ""].join(" ")}
									onClick={() => {setActive(d.id);}}
									style={{
										left: d.left,
										right: d.right,
										top: d.top,
										bottom: d.bottom,
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">  <path d="M12.9 23.6h-1.7V12.8H.4v-1.5h10.8V.4h1.7v10.8h10.7v1.5H12.9v10.9z"></path></svg>
								</button>
							);
						})}
				</div>

				<div className="radiance-slide4-content-right">
					<div className="features">
						{features.map(f => {
							return (
								<div
									tabIndex="-1"
									key={f.id}
									className={["feature", active === f.id ? "active" : ""].join(" ")}>	
										<div className="featureDesc">{f.desc}</div>
								</div>
							);
						})}
					</div>
				</div>
				

				
			</div >
		);
	}

	return (
		<section >
					<Product					
						dots={[
							{ id: 1, bottom: `${isMobile? "20px" : "20px" }`, right: `${isMobile ? "-20px" : "148px" }` },
							{ id: 2, left: `${isMobile? "-40px" : "153px" }`, top: `${isMobile ? "77px" : "80px"}` },
							{ id: 3, left: `${isMobile ? "-40px" : "150px"}`, top: `${isMobile ? "0" : "0"}`},
						
						]}
						features={[
							{
								id: 1,
								desc: <Fragment><p><strong>Compact size</strong>, nomad travel-friendly and easy to use</p></Fragment>,
							},
							{
								id: 2,
								desc: <Fragment><p>2 speeds of gentle vibrations, to <strong>reduce bags under eyes and increase the efficacy</strong> of your eye cream</p></Fragment>,							
							},
							{
								id: 3,
								desc: <Fragment><p>Red LED light <strong>boosts collagen and minimises crow’s feet</strong></p></Fragment>,								
							},
							
							
							
						]}
					/>


		</section>
	);
}

export default FacialBrushRadianceFeatures;