import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Container from '../components/Container';
import {Helmet} from "react-helmet";

import '../css/WTB.css';

import WtbBanner from '../images/WtbBanner.jpg';
import WtbProds from '../images/WtbProds.jpg';
import WtbBannerMobile from '../images/WtbBannerMobile.jpg';
import WtbProdsLogo from '../images/WtbProdsLogo.png';
import WtbProdsProds from '../images/WtbProdsProds.jpg';
import AmazonLogo from '../images/amazon.jpg';
import JohnLewisLogo from '../images/john-lewis.jpg';
import SelfridgesLogo from '../images/Selfridges.jpg';

const WTB = () => {
  return (
    <div className="Where-to-buy">
    	<Helmet title="Rowenta | Where to buy"  ></Helmet>
			<Breadcrumb />
			<Container>
				<div className="Where-to-buy">
					<div className="main-content">
						{window.innerWidth > 768 ? (
					<img src={WtbBanner} />
					) :
							<img src={WtbBannerMobile} />
						}
						<div className="WtbText">
							<h2>As the manufacturer, we at Rowenta do not sell our products directly to you, the customer.</h2>
							<p>Our products can be found in many high street stores as well as department stores, electrical and independent retailers.<br />
						Discover Rowenta products from the stores below:</p>
					</div>
						<hr />
						{window.innerWidth > 768 ? (
					<img src={WtbProds} />
					) :
							<div className="WtbProds">
								<img src={WtbProdsLogo} />
								<img src={WtbProdsProds} />
							</div>
						}

						{/* <div className="store-link">
							<a href="http://www.homeandcook.co.uk" target="_blank">
								<img src={HomeandCookLogo} />
								<p>visit homeandcook.co.uk</p>
							</a>
						</div> */}
						<div style={{textAlign: 'center'}}>
							<a className="btn shopnow" href="http://www.homeandcook.co.uk/15/rowenta">
								Shop Now
							</a>
						</div>
						<h3 style={{fontSize: 30}}>Other retailers:</h3>
						<div className="retailers-row">
							<a className="retailer" href="https://www.amazon.co.uk/Rowenta/b/ref=bl_dp_s_web_1733714031?ie=UTF8&node=1733714031&field-lbr_brands_browse-bin=Rowenta"><img src={AmazonLogo} /></a>
							<a className="retailer" href="https://www.johnlewis.com/search?search-term=rowenta"><img src={JohnLewisLogo} /></a>
							<a className="retailer" href="http://www.selfridges.com/GB/en/cat/rowenta/"><img src={SelfridgesLogo} /></a>
						</div>
					</div>
				</div>
		</Container>
    </div>
  );
};

export default WTB;