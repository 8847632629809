import React from 'react'
import '../css/FeaturedProducts.css';
import DW9230 from '../images/DW9230.png';
import DW5110 from '../images/DW5110.png';
import DW6010 from '../images/DW6010.png';
import DG8960G0 from '../images/DG8960G0.png';
import PU3030F0 from '../images/PU3030F0.png';
import VU6210F0 from '../images/VU6210F0.png';
import VU2630F0 from '../images/VU2630F0.png';
import VU1950F0 from '../images/VU1950F0.png';

import Container from './Container';
import SectionTitle from './SectionTitle';
import ProductPreview from '../components/ProductPreview';

const FeaturedProducts = (props) => {
	
	return(
		<section className="FeaturedProducts">
    	<Container>
				<SectionTitle topTitle="Featured" bottomTitle="Products" color="#A4B0B2"/>
				<div className="Products">
					{props.location.pathname === "/" && (
						[
							<ProductPreview permalink="/linen-care/steam-irons/DW6010" title="Rowenta Eco Focus DW6010 Steam Iron" desc="Save 30% energy" image={DW6010} category="linen-care" subcategory="steam-irons" product="DW6010"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-irons/DW9230" title="Rowenta Steam Force DW9230 Steam Iron" desc="Ironing made simple" image={DW9230} category="linen-care" subcategory="steam-irons" product="DW9230"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-irons/DW5110" title="Rowenta Focus DW5110 Steam Iron" desc="Ironing made simple" image={DW5110} category="linen-care" subcategory="steam-irons" product="DW5110"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-gens/DG8960" title="Rowenta Silence DG8960 Steam Generator Iron" desc="Fast Ironing" image={DG8960G0} category="linen-care" subcategory="steam-gens" product="DG8960G0"></ProductPreview>
						]
					)}
					{props.location === "linen-care" && (
						[
							<ProductPreview permalink="/linen-care/steam-irons/DW6010" title="Rowenta Eco Focus DW6010 Steam Iron" desc="Save 30% energy" image={DW6010} category="linen-care" subcategory="steam-irons" product="DW6010"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-irons/DW9230" title="Rowenta Steam Force DW9230 Steam Iron" desc="Ironing made simple" image={DW9230} category="linen-care" subcategory="steam-irons" product="DW9230"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-irons/DW5110" title="Rowenta Focus DW5110 Steam Iron" desc="Ironing made simple" image={DW5110} category="linen-care" subcategory="steam-irons" product="DW5110"></ProductPreview>,
							<ProductPreview permalink="/linen-care/steam-gens/DG8960" title="Rowenta Silence DG8960 Steam Generator Iron" desc="Fast Ironing" image={DG8960G0} category="linen-care" subcategory="steam-gens" product="DG8960G0"></ProductPreview>
						]
					)}
					{props.location === "home-comfort" && (
						[
							<ProductPreview permalink="/home-comfort/air-purifiers/PU3030F0" title="ROWENTA PURE AIR PU3030F0 AIR PURIFIER" desc="Up to 99.99% of allergens and fine particles filtered*" image={PU3030F0} category="home-comfort" subcategory="air-purifiers" product="PU3030F0"></ProductPreview>,
							<ProductPreview permalink="/home-comfort/fans/VU6210F0" title="ROWENTA EOLE COMPACT VU6210F0 FAN" desc="Airflow always at the right level" image={VU6210F0} category="home-comfort" subcategory="fans" product="VU6210F0"></ProductPreview>,
							<ProductPreview permalink="/home-comfort/fans/VU2630F0" title="ROWENTA TURBO SILENCE DESK EXTREME VU2630F0 TABLE FAN 12" desc="Power you can't hear" image={VU2630F0} category="home-comfort" subcategory="fans" product="VU2630F0"></ProductPreview>,
							<ProductPreview permalink="/home-comfort/fans/VU1950F0" title="ROWENTA CLASSIC MINI STAND FAN VU1950F0" desc="Simply refresh" image={VU1950F0} category="home-comfort" subcategory="fans" product="VU1950F0"></ProductPreview>
						]
					)}
				</div>
			</Container>
		</section>
	);
}

export default FeaturedProducts;