import React from 'react'
import { Link } from 'react-router-dom';

const WTB = props => {
	const data = props.data;
	console.log(data)
	if (data.inStock) {
		return (
			<a href={data.homeAndCookUrl} className="btn btn-primary btn-big" title="Buy Online">BUY ONLINE</a>
		);
	} else {
		return (
			<Link to="/where-to-buy" className="btn btn-primary btn-big" title="Where to Buy">WHERE TO BUY</Link>
		);
	};

};

export default WTB;
