import React, { Component } from "react";
import ReactCompareImage from "react-compare-image";
import "../css/BeforeAfterMobileAntiblemish.css";

class BeforeAfterMobileAntifatigue extends Component {
 
  render() {
    const before =
      "/beauty-images/antifatigue-mobile-before.jpg";
    const after =
      "/beauty-images/antifatigue-mobile-after.jpg";

    return (
      <div className="mainWrapper">
        <div className="contentWrapper">
          <ReactCompareImage leftImage={before} rightImage={after} />
          
        </div>
      </div>
    );
  }
}

export default BeforeAfterMobileAntifatigue;
