import React, { Component } from 'react';

import Container from '../components/Container';
import {Helmet} from "react-helmet";

import '../css/Acessories.css';

import AccessoriesImage from '../images/accessories.png';

class Accessories extends Component {

	render() {
		const Store = ({flag, country, text, link}) => (
			<a className="store" href={link}>
				<a className="store"></a>
				<img src={flag} alt={country} />
				<p>{text}</p>
			</a>
		);

    return (
      <div className="accessories">
      	<Helmet title="Rowenta | Accessories" />
				<section id="main" className="page">
					<Container>
						<h1>Buy Accessories</h1>
						<p>Contact one of our authorised service agents who will order the items direct from the official supplier for ROWENTA<sup>&reg;</sup> accessories.</p>
						<p><a href="http://www.rowenta.co.uk/repairs">
Click here</a> to see the contact details for service Agents in your country</p>
						<div className="content">
							<div className="img">
								<img src={AccessoriesImage} alt="Accessories" />
							</div>
							{/* <div className="stores">
								<Store
									flag="http://tefal.com/medias/Flag-of-the-United-Kingdom.PNG?context=bWFzdGVyfGltYWdlc3wxNTc4fGltYWdlL3BuZ3xpbWFnZXMvaDg0L2g1NS84ODA0ODA0MTk4NDMwLnBuZ3wzMjdkZjdlYjIyOGRlNGMwZTJjYTJkOTBlZmU2ZjZkZTJlMDk3MDcwM2UxN2NjNWMxM2JiN2U5Y2Q5NGRlMGU0&attachment=true"
									country="UK"
									text={<span>UK customers <span className="link">click here</span></span>}
									link="http://www.accessories.home-and-cook.com/seb/gb/en/home"
								/>
								<Store
									flag="http://tefal.com/medias/Flag-of-Ireland.PNG?context=bWFzdGVyfGltYWdlc3wyOTR8aW1hZ2UvcG5nfGltYWdlcy9oOTAvaDU4Lzg4MDQ4MDM5MDM1MTgucG5nfDFhODA5YTE5YjhkNzUzMzRmMmFhMjY2NzQ4NmZkMjQ4ZjUxNmJlMDMwZjBmNmQ1ZDM3OGU4MjAyNDg3OTA3MWY&attachment=true"
									country="Ireland"
									text={<span>Republic of Ireland customers <span className="link">click here</span></span>}
									link="http://www.accessories.home-and-cook.com/seb/ie/en/home"
								/>
							</div> */}
						</div>
					</Container>
				</section>
      </div>
    );
  }
}

export default Accessories;