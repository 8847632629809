import React, { Component } from 'react'
import '../css/SubNav.css';
import { Link } from 'react-router-dom';

import Container from './Container';

export default class SubNav extends Component {
	render() {
		return (
			<section className="SubNav">
				<Container>
					<nav>
						<ul>							
							<li>
								<Link to="/linen-care">Linen Care</Link>
							</li>
							<li>
								<Link to="/home-comfort">Home Comfort</Link>
							</li>
							<li>
								<Link to="/personal-care/skin-care-devices">Personal Care</Link>
							</li>
							<li className="end-child">
								<a href="/accessories" target="_blank" rel="noopener noreferrer">
									Accessories</a>
							</li>
						</ul>
					</nav>
				</Container>
			</section>
		);
	}
}




