import React, { Component } from "react";
import ReactCompareImage from "react-compare-image";
import "../css/BeforeAfterMobileRadiance.css";

class BeforeAfterMobileRadiance4 extends Component {
 
  render() {
    const before =
      "/facial-images/radiance4-mobile-before-visual.jpg";
    const after =
      "/facial-images/radiance4-mobile-after-visual.jpg";

    return (
      <div className="mainWrapper">
        <div className="contentWrapper">
          <ReactCompareImage leftImage={before} rightImage={after} />
          
        </div>
      </div>
    );
  }
}

export default BeforeAfterMobileRadiance4;
