import React, { useState, Component } from "react";
import BeforeAfterSlider from "react-before-after-slider";
import Carousel from "nuka-carousel";
import "../css/Discover.css";
// import { HashLink as Link } from 'react-router-hash-link';
import { Link } from "react-scroll";

import clinicallyProven from "../images/lift-images/lift-comfort-clinically-proven.png";
import liftBeforeAfter from "../images/lift-images/liftBeforeAfter.png";
import liftComfortProduct from "../images/lift-images/liftcomfort-product.png";

import liftcomfortWanttoBlur from "../images/lift-images/liftcomfort-wantto-blur.jpeg";
import liftcomfortWanttoLift from "../images/lift-images/liftcomfort-wantto-lift.jpeg";
import BuyOnlineButtonsGroup from "./buyOnlineButtonsGroup";

const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;

class BeforeAfter extends Component {
  render() {
    const before = "/beauty-images/antiblemish-before.jpg";
    const after = "/beauty-images/antiblemish-after.jpg";
    return (
      <BeforeAfterSlider
        before={before}
        after={after}
        width={!isMobile ? 920 : 340}
        height={!isMobile ? 360 : 360}
      />
    );
  }
}

const Discover = () => {
  const [text, setText] = useState(
    <div>
      <h4>Face Contour and Neck Head</h4>
      <ul></ul>
    </div>
  );
  const [active, setActive] = useState(1);

  const MoreButton = ({
    buttonNumber,
    displaytext,
    setText,
    setActive,
    active,
    isLive,
  }) => {
    const handleClick = () => {
      setText(displaytext);

      setActive(buttonNumber);
      console.log(active);
    };

    return (
      <div
        className={[
          "more-button",
          isLive ? isLive : " ",
          `more-button-${buttonNumber}`,
        ].join(" ")}
        onClick={() => handleClick()}
      ></div>
    );
  };

  return (
    <div className="antiblemish lift-page-discover" id="antiblemish">
      {!isMobile ? (
        <>
          <div className="clinicallyProven-image-container">
            <img alt="" src={clinicallyProven} />
          </div>

          <div>
            <div className="carouselWrapper">
              <Carousel
                // autoplay
                wrapAround
                initialSlideHeight={650}
                height={650}
                heightMode={false}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={previousSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="leftArrow"
                    >
                      <path
                        d="M41.406 81l-40-40 40-40"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="leftArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={nextSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="rightArrow"
                    >
                      <path
                        d="M1 81l40-40L1 1"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="rightArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                  <div className="circles">
                    <div
                      role="button"
                      className={[
                        currentSlide === 0 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(0)}
                    />
                    <div
                      role="button"
                      className={[
                        currentSlide === 1 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(1)}
                    />
                    <div
                      role="button"
                      className={[
                        currentSlide === 2 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(2)}
                    />
                  </div>
                )}
              >
                <div className="discover-slide discover-slide1">
                  <div className="discover-slide-header">
                    <h3>I want to</h3>
                  </div>
                  <div className="discover-slide1-inner">
                    <figure className="liftComfort-image-container liftComfort-image1">
                      <Link className="internal-link" to="faceContourAnchor">
                        <img
                          alt=""
                          className="liftComfort-image"
                          src={liftcomfortWanttoLift}
                        />
                        <figcaption>
                          <span>Lift my face contour</span>
                        </figcaption>
                      </Link>
                    </figure>
                    <figure className="liftComfort-image-container liftComfort-image2">
                      <Link className="internal-link" to="EyeContour">
                        <img
                          alt=""
                          className="liftComfort-image"
                          src={liftcomfortWanttoBlur}
                        />
                        <figcaption>
                          <span>
                            Blur signs of fatigue <br />
                            and reduce eye bags
                          </span>
                        </figcaption>
                      </Link>
                    </figure>
                  </div>
                </div>
                <div className="discover-slide discover-slide2">
                  <div className="discover-slide-header">
                    <h3>Before - After</h3>
                    <p className="lift-slide2-subtitle">
                      Proof of <strong>efficacy</strong>
                    </p>
                  </div>
                  <div className="discover-slide2-inner">
                    <div className="slide2-inner-section">
                      <img alt="" src={liftBeforeAfter} />
                    </div>
                    <div className="slide2-inner-section slide2-section2">
                      <h4>
                        Flash effect <sup>*</sup>
                      </h4>
                      <strong>92%</strong> <span>Radiance boosting effect</span>{" "}
                      <br />
                      <strong>96%</strong> <span>Healthy glow effect</span>{" "}
                      <br />
                      <strong>82%</strong> <span>Pores less visible</span>{" "}
                      <br />
                      <strong>97%</strong> <span>Drains the eye contour</span>{" "}
                      <br />
                      <br />
                      <h4>
                        Over time <sup>*</sup>
                      </h4>
                      <strong>83%</strong> <span>Sublimated skin</span> <br />
                      <strong>72%</strong>{" "}
                      <span>Face contour is restructured</span> <br />
                      <strong>76%</strong>{" "}
                      <span>
                        Wrinkles and fine lines appear less pronounced
                      </span>
                    </div>
                  </div>
                  <p className="disclaimer">
                    <sup>*</sup>
                    <i> Perception test, 34 volunteers - France – 2019</i>
                  </p>
                </div>
                <div className="discover-slide discover-slide3">
                  <div className="discover-slide-header">
                    <h3>Discover its performance</h3>
                  </div>
                  <figure className="slide3-inner-section">
                    <div className="slide3-image-container">
                      <img alt="" src={liftComfortProduct} class="" />
                    </div>

                    <figcaption class="">
                      <MoreButton
                        buttonNumber={1}
                        displaytext={
                          <>
                            <h4>Face Contour and Neck Head</h4>
                            <ul></ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 1}
                      ></MoreButton>
                      <MoreButton
                        buttonNumber={2}
                        displaytext={
                          <>
                            <h4>Battery and mode indicator</h4>
                            <ul></ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 2}
                      ></MoreButton>
                      <MoreButton
                        buttonNumber={3}
                        displaytext={
                          <>
                            <h4>Boost Button</h4>

                            <ul>
                              <li>1x to upgrade the speed: flash effect</li>
                              <li>2x to go back to normal speed</li>
                            </ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 3}
                      ></MoreButton>
                      <MoreButton
                        buttonNumber={4}
                        displaytext={
                          <>
                            <h4>Mode Button</h4>

                            <ul>
                              <li>Move up for Auto mode</li>

                              <li>
                                Move down for Manu mode and personalise your
                                routine
                              </li>
                            </ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 4}
                      ></MoreButton>
                      <MoreButton
                        buttonNumber={5}
                        displaytext={
                          <>
                            <h4>Direction Button</h4>

                            <ul>
                              <li>
                                Click right to start on the right side of your
                                face
                              </li>

                              <li>
                                Or click left to start on the left side of your
                                face
                              </li>
                            </ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 5}
                      ></MoreButton>

                      <MoreButton
                        buttonNumber={6}
                        displaytext={
                          <>
                            <h4>Charging base</h4>
                            <ul></ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 6}
                      ></MoreButton>
                      <MoreButton
                        buttonNumber={7}
                        displaytext={
                          <>
                            <h4> Eye Contour Head</h4>

                            <ul>
                              <li>
                                After choosing your mode, press the direction
                                button (no matter which arrow) to start your eye
                                contour routine
                              </li>
                            </ul>
                          </>
                        }
                        setText={setText}
                        setActive={setActive}
                        active={active}
                        isLive={active === 7}
                      ></MoreButton>
                      <div className="discover-descripton">{text}</div>
                    </figcaption>
                  </figure>
                </div>
              </Carousel>
              <br />
              <BuyOnlineButtonsGroup />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="discover-slide discover-slide1">
            <div className="discover-slide-header">
              <div></div>
              <h3>I want to</h3>
              <div className="clinicallyProven-image-container-mobile">
                <img alt="" src={clinicallyProven} />
              </div>
            </div>
            <div className="discover-slide1-inner">
              <figure className="liftComfort-image-container liftComfort-image1">
                <Link className="internal-link" to="faceContourAnchor">
                  <img
                    alt=""
                    className="liftComfort-image liftComfort-image-1"
                    src={liftcomfortWanttoLift}
                  />
                  <figcaption>
                    <span>Lift my face contour</span>
                  </figcaption>
                </Link>
              </figure>
              <figure className="liftComfort-image-container liftComfort-image2">
                <Link className="internal-link" to="EyeContour">
                  <img
                    alt=""
                    className="liftComfort-image liftComfort-image-2"
                    src={liftcomfortWanttoBlur}
                  />
                  <figcaption className="caption-2">
                    <span>
                      Blur signs of fatigue <br />
                      and reduce eye bags
                    </span>
                  </figcaption>
                </Link>
              </figure>
            </div>
            <BuyOnlineButtonsGroup />
          </div>
          <div className="divider"></div>
          <div className="discover-slide discover-slide2">
            <div className="discover-slide-header">
              <div></div>
              <div>
                <h3>Before - After</h3>
                <p className="lift-slide2-subtitle">
                  Proof of <strong>efficacy</strong>
                </p>
              </div>
              <div className="clinicallyProven-image-container-mobile">
                <img alt="" src={clinicallyProven} />
              </div>
            </div>
            <div className="discover-slide2-inner">
              <div className="slide2-mobile-inner-section">
                <img alt="" src={liftBeforeAfter} />
              </div>
              <div className="slide2-inner-section-mobile slide2-section2">
                <h4>
                  Flash effect <sup>*</sup>
                </h4>
                <strong>92%</strong> <span>Radiance boosting effect</span>{" "}
                <br />
                <strong>96%</strong> <span>Healthy glow effect</span> <br />
                <strong>82%</strong> <span>Pores less visible</span> <br />
                <strong>97%</strong> <span>Drains the eye contour</span> <br />
                <br />
                <h4>
                  Over time <sup>*</sup>
                </h4>
                <strong>83%</strong> <span>Sublimated skin</span> <br />
                <strong>72%</strong> <span>Face contour is restructured</span>{" "}
                <br />
                <strong>76%</strong>{" "}
                <span>
                  Wrinkles and fine lines appear less{" "}
                  <span class="pronounced">pronounced</span>
                </span>
              </div>
            </div>
            <br />
            <BuyOnlineButtonsGroup />
            <p className="disclaimer">
              <sup>*</sup> Perception test, 34 volunteers - France – 2019
            </p>
          </div>
          <div className="divider"></div>
          <div className="discover-slide discover-slide3">
            <div className="discover-slide-header">
              <div></div>
              <h3>Discover its performance</h3>
              <div className="clinicallyProven-image-container-mobile">
                <img alt="" src={clinicallyProven} />
              </div>
            </div>
            <figure className="slide3-inner-section">
              <div className="slide3-image-container">
                <img alt="" src={liftComfortProduct} class="" />
              </div>

              <figcaption class="">
                <MoreButton
                  buttonNumber={1}
                  displaytext={
                    <>
                      <h4>Face Contour and Neck Head</h4>
                      <ul></ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 1}
                ></MoreButton>
                <MoreButton
                  buttonNumber={2}
                  displaytext={
                    <>
                      <h4>Battery and mode indicator</h4>
                      <ul></ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 2}
                ></MoreButton>
                <MoreButton
                  buttonNumber={3}
                  displaytext={
                    <>
                      <h4>Boost Button</h4>

                      <ul>
                        <li>1x to upgrade the speed: flash effect</li>

                        <li>2x to go back to normal speed</li>
                      </ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 3}
                ></MoreButton>
                <MoreButton
                  buttonNumber={4}
                  displaytext={
                    <>
                      <h4>Mode Button</h4>

                      <ul>
                        <li>Move up for Auto mode</li>

                        <li>
                          Move down for Manu mode and personalise your routine
                        </li>
                      </ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 4}
                ></MoreButton>
                <MoreButton
                  buttonNumber={5}
                  displaytext={
                    <>
                      <h4>Direction Button</h4>

                      <ul>
                        <li>
                          Click right to start on the right side of your face
                        </li>

                        <li>
                          Or click left to start on the left side of your face
                        </li>
                      </ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 5}
                ></MoreButton>
                <MoreButton
                  buttonNumber={6}
                  displaytext={
                    <>
                      <h4>Charging base</h4>
                      <ul></ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 6}
                ></MoreButton>
                <MoreButton
                  buttonNumber={7}
                  displaytext={
                    <>
                      <h4> Eye Contour Head</h4>

                      <ul>
                        <li>
                          After choosing your mode, press the direction button
                          (no matter which arrow) to start your eye contour
                          routine
                        </li>
                      </ul>
                    </>
                  }
                  setText={setText}
                  setActive={setActive}
                  active={active}
                  isLive={active === 7}
                ></MoreButton>

                <div className="discover-descripton">{text}</div>
              </figcaption>
            </figure>
            <br />
            <br />
            <BuyOnlineButtonsGroup classes="marginTop100" />
          </div>
        </>
      )}
    </div>
  );
};

export default Discover;
