import React, { useState } from "react";

import '../css/FAQ.css';

const Accordion = () => {
	const [active, setActive] = useState();
	

	const KeyValuesAccordionContent = ({ index, title, desc, open }) => {
		return (
			<div className="keyvalue_accordion_inner">
				<div  className={['keyvalue_accordion', 'keyvalue_accordion_item', open ? open : ""].join(" ")}>
					
						<button onClick={() => setActive(index)} style={{display: open ? "none" : "flex"}} className="keyvalue_accordion_header">
							<h4 dangerouslySetInnerHTML={{ __html: title }} />
							<div className="close"><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
								
							</span></div>
						</button>
					
						<div style={{display: open ? "block" : "none"}} className='keyvalue_accordion_item'>
							<div className='keyvalue_accordion_content'>
								<div className='keyvalue_accordion_content_left'>
									<h4 dangerouslySetInnerHTML={{ __html: title }} />
									<p dangerouslySetInnerHTML={{ __html: desc }} />
								</div>
								<div className='keyvalue_accordion_content_right'>
									<div onClick={() => setActive(0)} className='close'><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
										</span>
									</div>
								</div>
							</div>
						</div>
				
				</div>
			</div>
		);
	}

	return (
		<section id="keyvalue">
			<div className='keyvalue_accordion'>
				<KeyValuesAccordionContent
					index={1}
					open={active === 1}	
                    title="<span>1/</span> What kind of products can I use with the Anti-Blemish Facial Brush?"				
					desc="<p>Face cleansers such as cleansing foams, waters, gels or soaps are all compatibles with this appliance.</p>
					<p>The Anti-Blemish Facial Brush exfoliates your skin gently, therefore we advise you against using exfoliating and abrasive products such as scrubs or clays with the appliance. For further information, we advise you to check the user manual.</p>"					
				/>
				<KeyValuesAccordionContent
					index={2}
					open={active === 2}
					title="<span>2/</span> Can I use the Facial Brush under water?"
					desc="<p>Yes, this brush is 100% waterproof and can be used when shower. Nevertheless, the charging base must remain in a dry and cool place.</p>"					
				/>
				<KeyValuesAccordionContent
					index={3}
					open={active === 3}
					title="<span>3/</span> How does the sanitizing zone work?"
					desc="<p>Blue LED lights are known for their purifying properties. For a purifying action, the brush head must remain at least 3 hours in the blue LED light sanitising zone.</p>
					<p>You have to charge your device in order to activate the sanitising zone.</p>
					<p class='footnote'>References: The effective treatment of acne vulgaris by a high-intensity, narrow band 405-420nm light source. Monica Elman, Michael Slatkine &amp; Yoram Harth, J Cosmetic &amp; Laser Ther 2003; 5: 111-116.</p>"					
				/>
				<KeyValuesAccordionContent
					index={4}
					open={active === 4}
					title="<span>4</span> When and how do I have to clean the brush heads?"
					desc="<p>The brush should be rinsed under water after each use and must be pre-dried with a towel. For better results, brush heads must be changed every 3 months. You can shop it in registered retailers and <a href='https://www.argos.co.uk/brands/rowenta'>online</a>.</p>"					
				/>
				<KeyValuesAccordionContent
					index={5}
					open={active === 5}
					title="<span>5</span> How can I charge my Facial Brush?"
					desc="<p>To charge your appliance, please only use the charging base provided with the product. Charge is made by induction: you simply need to make sure the charging base is plugged and that the Facial Brush is correctly inserted in the base. When the Facial Brush is charging, the sanitizing zone blue LED lights turn on.</p>"					
				/>
				<KeyValuesAccordionContent
					index={6}
					open={active === 6}
					title="<span>6</span> Does the Anti-Blemish Facial Brush suit my dry skin?"
					desc="<p>All 3 brush heads (Purifying, Sensitive, Delicate Silicone) suit every skin type. However, for sensitive and delicate skins the Sensitive brush head or the Delicate Silicone brush head may suit your skin better, they have been especially designed for sensitive and delicate skins.</p>

					<p>You can also purchase the Sensitive brush head or the Delicate Silicone brush head separately as accessories <a href='https://www.argos.co.uk/brands/rowenta'>here</a>.</p>"					
				/>
				<KeyValuesAccordionContent
					index={7}
					open={active === 7}
					title="<span>7</span> Can I use the Anti-Blemish Facial Brush if I have acne?"
					desc="<p>We advise you to contact a doctor or dermatologist if you suffer from any skin conditions before using My Beauty Routine products.</p>"					
				/>



				
			</div>
		</section>
	);
}

export default Accordion;
