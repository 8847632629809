import React, {Component } from "react";
import BeforeAfterSlider from "react-before-after-slider";
import Carousel from "nuka-carousel";

import "../css/EyeContour.css";

import liftComfortEyecontourProduct from "../images/lift-images/liftcomfort-eyecontour-product.png";
import liftComfortEyeBenefits from "../images/lift-images/eye-benefits.jpg";
import liftComfortEyeBenefitsMobile from "../images/lift-images/eyecontour1.jpg";

import liftComfortEyeHowTo1 from "../images/lift-images/liftcomfort-eyehowto1.jpeg";
import liftComfortEyeHowTo2 from "../images/lift-images/liftcomfort-eyehowto2.jpeg";
import liftComfortEyeHowTo3 from "../images/lift-images/liftcomfort-eyehowto3.jpeg";
import liftComfortEyeHowTo4 from "../images/lift-images/liftcomfort-eyehowto4.jpeg";

import BuyOnlineButtonsGroup from "./buyOnlineButtonsGroup";

const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;

class BeforeAfter extends Component {
  render() {
    const before = "/beauty-images/antiblemish-before.jpg";
    const after = "/beauty-images/antiblemish-after.jpg";
    return (
      <BeforeAfterSlider
        before={before}
        after={after}
        width={!isMobile ? 920 : 340}
        height={!isMobile ? 360 : 360}
      />
    );
  }
}

const EyeContour = () => {
  return (
    <section className="antiblemish EyeContour" id="EyeContour">
      <header class="EyeContour-header">
        <div className="EyeContour-header-inner">
          <h2>Attachment #2: Eye Contour</h2>
          <p>Blurs signs of fatigue and reduces eye bags</p>
        </div>
      </header>
      {!isMobile ? (
        <>
          <div>
            <div className="carouselWrapper">
              <Carousel
                // autoplay
                wrapAround
                initialSlideHeight={630}
                height={630}
                heightMode={false}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={previousSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="leftArrow"
                    >
                      <path
                        d="M41.406 81l-40-40 40-40"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="leftArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={nextSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="rightArrow"
                    >
                      <path
                        d="M1 81l40-40L1 1"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="rightArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                  <div className="circles">
                    <div
                      role="button"
                      className={[
                        currentSlide === 0 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(0)}
                    />
                    <div
                      role="button"
                      className={[
                        currentSlide === 1 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(1)}
                    />
                  </div>
                )}
              >
                <div className="EyeContour-slide EyeContour-slide1">
                  <div className="EyeContour-slide-header">
                    <h3>Benefits for your skin</h3>
                  </div>
                  <div className="EyeContour-slide1-inner">
                    <figure className="EyeContour-image-container EyeContour-image1">
                      <img
                        alt=""
                        className="EyeContour-image"
                        src={liftComfortEyeBenefits}
                      />
                    </figure>
                    <figure className="EyeContour-image-container EyeContour-image2">
                      <img
                        alt=""
                        className="EyeContour-product-image"
                        src={liftComfortEyecontourProduct}
                      />
                      <figcaption>
                        <p>
                          <strong>96%</strong> tiredness signs and{" "}
                          <strong>68%</strong> bags <br />
                          under the eyes are reduced <sup>*</sup>
                        </p>
                      </figcaption>
                    </figure>
                  </div>                
                  <br /><p className="disclaimer">
                    <sup>*</sup>
                    <i> Perception test, 34 volunteers - France – 2019</i>
                  </p>
                </div>
                <div className="EyeContour-slide EyeContour-slide2">
                  <div className="EyeContour-slide-header">
                    <h3>How to use</h3>
                    <p className="EyeContour-slide2-subtitle">
                      <strong>2X3 MIN</strong> skincare ritual for a more
                      youthful, sublimated skin <br />
                      <strong>4 times</strong> a week morning or evening
                    </p>
                  </div>
                  <div className="EyeContour-slide2-inner">
                    <figure>
                      <img alt="" src={liftComfortEyeHowTo1} />
                      <figcaption>
                        <strong>1</strong>{" "}
                        <span>
                          For more comfort, apply your usual Eye Contour cream
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img
                        alt=""
                        class="slide2-figure-image-two"
                        src={liftComfortEyeHowTo2}
                      />
                      <figcaption class="slide2-figcaption-two">
                        <strong>2</strong>{" "}
                        <span>
                          Choose your mode: Auto or Manu. Click on the arrow
                          button &#40;no matter which arrow&#41;
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img
                        alt=""
                        class="slide2-figure-image-three"
                        src={liftComfortEyeHowTo3}
                      />
                      <figcaption class="slide2-figcaption-three">
                        <strong>3</strong>{" "}
                        <span>
                          Use under your eyes from inside to outside &#40;3 min
                          per eye recommended&#41;
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img alt="" src={liftComfortEyeHowTo4} />
                      <figcaption>
                        <strong>4</strong>{" "}
                        <span>Admire your visibly younger looking skin!</span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </Carousel>
              <br /><BuyOnlineButtonsGroup  />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="EyeContour-slide EyeContour-slide1">
            <div className="EyeContour-slide-header">
              <div className="mobile-header-image-container">
                {" "}
                <img
                  alt=""
                  // className="EyeContour-product-image"
                  src={liftComfortEyecontourProduct}
                />
              </div>
              <h3>Benefits for your skin</h3>
              <div></div>
            </div>
            <div className="EyeContour-slide1-inner">
              <figure className="EyeContour-slide1-image-container">
                <img
                  alt=""
                  className="EyeContour-image"
                  src={liftComfortEyeBenefitsMobile}
                />
              </figure>
              <figure className="EyeContour-image-container EyeContour-image2">
                <figcaption>
                  <p>
                    <strong>96%</strong> tiredness signs and{" "}
                    <strong>68%</strong> bags <br />
                    under the eyes are reduced <sup>*</sup>
                  </p>
                </figcaption>
              </figure>
            </div>
            <BuyOnlineButtonsGroup  classes="buttonContainer-slide1"/>           
            <p className="disclaimer">
              <sup>*</sup>
              <i> Perception test, 34 volunteers - France – 2019</i>
            </p>
          </div>
          <div className="divider"></div>
          <div className="EyeContour-slide EyeContour-slide2">
            <div className="EyeContour-slide2-mobile-header">
              <h3>How to use</h3>
              <p className="EyeContour-slide2-subtitle">
                <strong>2X3 MIN</strong> skincare ritual for a more youthful,
                sublimated skin <br />
                <strong>4 times</strong> a week morning or evening
              </p>
            </div>
            <div className="carouselWrapper">
              <div className="EyeContour-slide2-mobile-inner">
                <Carousel
                  autoplay
                  wrapAround
                  // initialSlideHeight={550}
                  // height={550}
                  heightMode={'max'}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      className="sliderArrows"
                      type="button"
                      onClick={previousSlide}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 42 82"
                        className="leftArrow"
                      >
                        <path
                          d="M41.406 81l-40-40 40-40"
                          fill="none"
                          stroke="white"
                          strokeLinecap="round"
                          strokeWidth="4"
                          className="leftArrow"
                        ></path>
                      </svg>
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      className="sliderArrows"
                      type="button"
                      onClick={nextSlide}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 42 82"
                        className="rightArrow"
                      >
                        <path
                          d="M1 81l40-40L1 1"
                          fill="none"
                          stroke="white"
                          strokeLinecap="round"
                          strokeWidth="4"
                          className="rightArrow"
                        ></path>
                      </svg>
                    </button>
                  )}
                  renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                    <div className="circles">
                      <div
                        role="button"
                        className={[
                          currentSlide === 0 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(0)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 1 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(1)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 2 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(2)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 3 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(3)}
                      />
                    </div>
                  )}
                >
                  <figure>
                    <img alt="" src={liftComfortEyeHowTo1} />
                    <figcaption>
                      <strong>1</strong>{" "}
                      <span>
                        For more comfort, apply your usual Eye Contour cream
                      </span>
                    </figcaption>
                  </figure>
                  <figure>
                    <img
                      alt=""
                      // class="slide2-figure-image-two"
                      src={liftComfortEyeHowTo2}
                    />
                    <figcaption>
                      <strong>2</strong>{" "}
                      <span>
                        Choose your mode: Auto or Manu. Click on the arrow
                        button &#40;no matter which arrow&#41;
                      </span>
                    </figcaption>
                  </figure>
                  <figure>
                    <img
                      alt=""
                      // class="slide2-figure-image-three"
                      src={liftComfortEyeHowTo3}
                    />
                    <figcaption
                    // class="slide2-figcaption-three"
                    >
                      <strong>3</strong>{" "}
                      <span>
                        Use under your eyes from inside to outside &#40;3 min
                        per eye recommended&#41;
                      </span>
                    </figcaption>
                  </figure>
                  <figure>
                    <img alt="" src={liftComfortEyeHowTo4} />
                    <figcaption>
                      <strong>4</strong>{" "}
                      <span>Admire your visibly younger looking skin!</span>
                    </figcaption>
                  </figure>
                </Carousel>
              </div>
            </div>
            <BuyOnlineButtonsGroup  classes="buttonContainer-slide2-mobile"/>
          </div>
        </>
      )}
    </section>
  );
};

export default EyeContour;
