import React from "react";
import SubCategoryBanner from "../components/SubCategoryBanner";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import Products from "../components/Products";
import productsData from "../data/products.json";
import ProductPreview from "../components/ProductPreview";

const SubCategory = (props) => {
  const productsList = productsData.map((product) => {
    if (props.data.includedProducts.includes(product.sku)) {
      console.log("product exists" + product.sku);
      return (
        <ProductPreview
          key={product.sku}
          title={product.title}
          desc={product.subtitle}
          permalink={product.permalink}
          product={product.sku}
          image={require(`../images/${product.sku}.png`)}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <div className="sub-category">
      <Helmet title={`Rowenta | ${props.data.title}`} />
      <Nav />
      <SubCategoryBanner data={props.data} />
      {window.location.pathname.includes("skin-care-devices") ? (
        <>
          <Products>{productsList.slice(0, 3)}</Products>
          <Products>{productsList.slice(3, 6)}</Products>
        </>
      ) : (
        <Products>{productsList}</Products>
      )}
    </div>
  );
};

export default SubCategory;
