import React, { useState, Fragment } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Container from "../components/Container";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../css/ContactUs.css";

const ContactUs = () => {
  const ContactPortal = () => {
    const [showIframe, setShowIframe] = useState(false);

    return (
      <div className="cnt-p">
        {showIframe && (
          <iframe
            src="https://groupe-seb.my.salesforce-sites.com/webform?Country=GB&Language=en_GB&brand=Rowenta"
            frameBorder="0"
            height="1000px"
            width="100%"
          />
        )}
        {!showIframe && (
          <Fragment>
            <p>
              If your request concerns general product information, use and care
              details, consumer policy or repair, we invite you to first of all
              consult our{" "}
              <a
                href="https://www.rowenta.com/faq/csc/products"
                target="_blank"
              >
                Frequently Asked Questions
              </a>
              ,{" "}
              <a
                href="https://www.rowenta.com/instructions-for-use"
                target="_blank"
              >
                Use &amp; Care
              </a>{" "}
              and <Link to="/guarantee">Guarantee sections</Link>.
            </p>
            <p>
              Non UK/ROI residents - please visit{" "}
              <a href="https://www.rowenta.com">www.rowenta.com</a>
            </p>
            <p>
              You can contact us using a simple webform to create a case, please
              select the topic related to your enquiry. Then complete the form
              that will appear and send it to us. We will reply to your query as
              quickly as possible.
            </p>
            <button
              class="btn btn-primary btn-big"
              onClick={() => setShowIframe(true)}
            >
              Create a case
            </button>
            <div className="cnt-p-details">
              <div className="cnt-p-telephone">
                <p>
                  <strong>
                    For your information our Customer Relations opening hours
                    are:
                  </strong>
                </p>
                <p>Monday to Friday 8.00am to 8.00pm</p>
                <br />
                <p>
                  <strong>Telephone Helplines are open Monday to Friday</strong>
                </p>
                <p>8.00am to 8.00pm:</p>
                <p>UK – 0345 602 1454</p>
                <p>ROI – 01 677 4003</p>
              </div>

              <div className="cnt-p-address">
                <p>
                  <strong>Our address is:</strong>
                </p>
                <address>
                  Groupe SEB UK Ltd.
                  <br />
                  Customer Relations
                  <br />
                  Ditton Park
                  <br />
                  Riding Court Road
                  <br />
                  Datchet
                  <br />
                  SL3 9LL
                </address>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <section id="main" class="page">
      <div className="Contact">
        <Helmet title="Rowenta | Contact us"></Helmet>
        <link
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossOrigin="anonymous"
        />
        <Breadcrumb />
        <div className="ContactUsBanner">
          <h1>Contact us</h1>
        </div>
        <Container>
          <ContactPortal />
        </Container>
      </div>
    </section>
  );
};

export default ContactUs;
