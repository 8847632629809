import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import "../css/Lift.css";

import HeaderBeauty from "../components/HeaderBeauty";
//import HeaderSliderLiftPage from "../components/HeaderSliderLiftPage";
import LiftCountourMassager from "../components/LiftCountourMassager";
import Discover from "../components/Discover";
import FaceContour from "../components/FaceContour";
import EyeContour from "../components/EyeContour";
import Tutorials from "../components/Tutorials";
import FAQLift from "../components/FAQLift";

import poster from "../images/facial-images/noflaws-poster.jpg";
import eyemassager from "../images/facial-images/product-noflaws-eye-massager.png";
import brush from "../images/facial-images/product-noflaws-brush.png";

import radiancebooster from "../images/radiancebooster-poster.jpg";
import eyemassagerGlow from "../images/glowskin-product-eyemassager.png";
import brushGlow from "../images/glowskin-product-brush.png";

const Lift = (props) => {
  if (window.location.pathname.includes("/personal-care/skin-care-devices/")) {
    return <Redirect to="/lift" />;
  }
  const width = (typeof window !== "undefined" && window.innerWidth) || 0;

  return (
    <div id="lift">
      <Helmet>
        <meta
          name="title"
          content="Lift Range - My Beauty Routine Skincare Devices - Rowenta UK"
        />
        <meta
          name="description"
          content="The Lift range, part of the My Beauty Routine collection of skincare devices by Rowenta. Bring Power to Your Skin."
        />
      </Helmet>
      {/* <HeaderSliderLiftPage /> */}
      <HeaderBeauty />
      <LiftCountourMassager />
      <Discover />
      <FaceContour />
      <EyeContour />
      <Tutorials />
      <FAQLift />
      <section className="no-flaws-banner">
        <figure>
          <img alt="" src={poster} className="collection-poster" />
          <img alt="" src={eyemassager} className="collection-eye-massager" />
          <img alt="" src={brush} className="collection-brush" />
        </figure>

        <h2>
          No Flaws
          <br />
          Collection
        </h2>
        <p>For a flawless skin tone</p>
        <Link
          to="/no-flaws"
          className="care-btn facial-care-btn"
          title="Discover"
        >
          Discover
        </Link>
      </section>
      <section className="glowskin-banner">
        <figure>
          <img alt="" src={radiancebooster} className="collection-poster" />
          <img
            alt=""
            src={eyemassagerGlow}
            className="collection-eye-massager"
          />
          <img alt="" src={brushGlow} className="collection-brush" />
        </figure>

        <h2>
          Glow Skin
          <br />
          Collection
        </h2>
        <p>For a younger looking skin</p>
        <Link to="/glow-skin" className="care-btn" title="Discover">
          Discover
        </Link>
      </section>
    </div>
  );
};

export default Lift;
