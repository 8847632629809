import React from 'react'
import '../css/Footer.css'

import Container from './Container'

const Footer = () => {
	return(
		<section className="Footer">
			<Container>
				<div className="FooterLogo">
					<a href="/">
						<img title="Rowenta International" alt="Rowenta International" src="https://www.rowentausa.com/medias/rowenta-logo.png?context=bWFzdGVyfGltYWdlc3wxNjUyfGltYWdlL3BuZ3xpbWFnZXMvaGRjL2gwNy84OTE1Njg4MzI1MTUwLnBuZ3w5NGYyMjRlOGNiZDI2ZTI5ODEzZDgzYzgwYjFmOWJhZTRkYWUzZGRjNmI1Mjg1Njc3YzFkZTY4OWRiNmYxMmNi" />
					</a>
				</div>
				<ul className="FooterLinks">
				<li>
						<a href="/cookies-policy" title="Cookies Policy" target="_blank" rel="noopener noreferrer">
							Cookies Policy
						</a>
					</li>
					<li>
						<a href="/privacy-policy" title="Privacy Policy" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
					</li>
					<li>
						<a href="/terms-and-conditions" title="Terms of use" target="_blank" rel="noopener noreferrer">
							Terms of use
						</a>
					</li>
					<li>
						<a href="http://www.groupeseb.com/en-en" title="Groupe SEB" target="_blank" rel="noopener noreferrer">
							Groupe SEB
						</a>
					</li>
					<li>
						<a href="http://www.groupeseb-careers.com/en" title="Careers" target="_blank" rel="noopener noreferrer">
							Careers
						</a>
					</li>
					<li>
						<a href="https://www.innovate-with-groupeseb.com/" title="Inventors" target="_blank" rel="noopener noreferrer">
							Inventors
						</a>
						</li>
					<li className="lang">
						<a href="http://www.rowenta.com/country-selector">
							<img title="" alt="" src="https://www.rowentausa.com/medias/world.png?context=bWFzdGVyfHJvb3R8MTY3N3xpbWFnZS9wbmd8aGVmL2g4YS84OTQyMjc1OTg1NDM4LnBuZ3xhNGJhMjNkYzkzYWU1YjE0MjJlYjQ5ZGY5NDM4NDZiMDMzNDMxYzYzN2I2ODVlZWJmMGYzZTRmNzViODAxMTE0" />
						</a>
					</li>
				</ul>
			</Container>
		</section>
	);
}

export default Footer;