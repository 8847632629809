import React, { useState } from "react";

import liftComfortFAQ from "../images/lift-images/liftcomfort-faq.jpeg";

import "../css/FAQLift.css";

const FAQLift = () => {
  const [product, setProduct] = useState("Facial");
  const [active, setActive] = useState();

  const KeyValuesAccordionContent = ({ index, title, desc, open }) => {
    return (
      <div className="keyvalue_accordion_inner">
        <div
          className={[
            "keyvalue_accordion",
            "keyvalue_accordion_item",
            open ? open : "",
          ].join(" ")}
          onClick={() => (active === index ? setActive(0) : setActive(index))}
        >
          <button
            style={{ display: open ? "none" : "flex" }}
            className="keyvalue_accordion_header"
          >
            <h4 dangerouslySetInnerHTML={{ __html: title }} />
            <div className="close">
              <span className={[open ? "open_cross" : "close_cross"].join(" ")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 42 82"
                  className="arrowButton"
                >
                  <path
                    d="M41.406 81l-40-40 40-40"
                    fill="none"
                    stroke=""
                    strokeLinecap="round"
                    strokeWidth="6"
                    className="arrowButton"
                  ></path>
                </svg>
              </span>
            </div>
          </button>

          <div
            style={{ display: open ? "block" : "none" }}
            className="keyvalue_accordion_item"
          >
            <div className="keyvalue_accordion_content">
              <div className="keyvalue_accordion_content_left">
                <h4 dangerouslySetInnerHTML={{ __html: title }} />
                <p dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
              <div className="keyvalue_accordion_content_right">
                <div onClick={() => setActive(0)} className="close">
                  <span
                    className={[open ? "open_cross" : "close_cross"].join(" ")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="arrowButton"
                    >
                      <path
                        d="M41.406 81l-40-40 40-40"
                        fill="none"
                        stroke=""
                        strokeLinecap="round"
                        strokeWidth="6"
                        className="arrowButton"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="faqSection">
      <div className="divider"></div>
      <h2>Your questions &amp; our answers</h2>
      <div className="faqSection-content">
        <div className="toggle">
          <div
            onClick={() => setProduct("Facial")}
            className={product === "Facial" ? "activetab" : ""}
          >
            <div className="facialButton">
              <img src={liftComfortFAQ} />
              Lift Contour <br />
              Massager
            </div>
          </div>
        </div>
        <div
          className="facialTab"
          style={{ display: product === "Facial" ? "flex" : "none" }}
        >
          <section id="keyvalue">
            <div className="keyvalue_accordion">
              <KeyValuesAccordionContent
                index={1}
                open={active === 1}
                title="<span>1 /</span> Which head I should use for which part of my face?"
                desc="<p>The Face &amp; Neck Head (with 3 balls) is designed to lift your neck, 
                face contour, cheeks and forehead. Don’t forget to make a move from the bottom upwards 
                to get the best results.</p><p>The Eye Contour Head (with 2 balls) should be used around
                 the eye area to help reduce eye bags and wrinkles.</p>"
              />
              <KeyValuesAccordionContent
                index={2}
                open={active === 2}
                title="<span>2 /</span> How often should I use the appliance to get results?"
                desc="<p>For best results, we recommend to use the product 4 times per week, 3 min per zone.</p>"
              />
              <KeyValuesAccordionContent
                index={3}
                open={active === 3}
                title="<span>3 /</span> How long does it take to see results?"
                desc="<p>The Lift Contour Massager delivers flash effect results right after the first use: radiant skin, 
                minimised pores, blurred signs of fatigue *. To extend and improve results over time, use the Lift Contour regularly. 
                After 28 days of use, 4 times a week*, your skin is firmed, and your contour is lifted**.</p>
                <p class='footnote'>* Perception test, 34 volunteers - France – 2019 ; ** 2 months clinical study – 34 volunteers – France – 2019</p>"
              />
              <KeyValuesAccordionContent
                index={4}
                open={active === 4}
                title="<span>4 /</span> Can I use the appliance under water?"
                desc="<p>The Lift Contour Massager is not waterproof. You must use it only in a dry area. However, you can rince the detachable heads 
                under water. Make sure they are totally dry before putting them back on the core device. To check any safety information, 
                please refer to the included IFU.</p>"
              />
              <KeyValuesAccordionContent
                index={5}
                open={active === 5}
                title="<span>5 /</span> How can I clean my product?"
                desc="<p>You should only rinse the Face Contour and Neck Head as well as the Eye Contour Head. 
                      Remove them from the body of your device, and rince them under water.</p>"
              />
              <KeyValuesAccordionContent
                index={6}
                open={active === 6}
                title="<span>6 /</span> How do I do to remove/change heads?"
                desc="<p>Detachable heads can be removed simply by pulling them up. You don’t need to realise any other manipulation.</p>"
              />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default FAQLift;
