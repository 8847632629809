import React from 'react';
import Container from '../components/Container';
import {Helmet} from "react-helmet";

import '../css/Privacy.css';

const Privacy = () => {
  return (
    <div className="Privacy">
    <Helmet title="Rowenta | Privacy policy"  ></Helmet>
    <Container>
				<div className="PrivacyRowenta">
					<h2 className="PrivacyRowentatitle">Privacy Policy</h2>
					<ul>
						<li><a href="#RowentaPrivacy1">What personal data is collected on the Web Site?</a></li>
						<li><a href="#RowentaPrivacy2">How is your data used?</a></li>
						<li><a href="#RowentaPrivacy3">Protecting your data</a></li>
						<li><a href="#RowentaPrivacy4">Who has access to your data?</a></li>
						<li><a href="#RowentaPrivacy5">Your choices in relation to direct marketing</a></li>
						<li><a href="#RowentaPrivacy6">Unsubscribing, deleting or amending your personal data when you have created a user account</a></li>
						<li><a href="#RowentaPrivacy7">Third party web sites</a></li>
						<li><a href="#RowentaPrivacy8">Use of your personal data from accounts created on another web site or service of the brand</a></li>
						<li><a href="#RowentaPrivacy9">Modification of the Privacy Policy</a></li>
						<li><a href="#RowentaPrivacy10">Your rights in relation to the processing of your personal data</a></li>
					</ul>
					<p className="PrivacyRowentaIntroLead"><strong>Applicable as of 05/01/2018</strong></p>
					<p>The privacy policy set out below (hereinafter the “Privacy Policy” or the “Policy”), explains the way in which the personal data collected on rowenta.co.uk (hereinafter the “Web Site”) by GROUPE SEB UK Ltd. and eventually by SEB International Services (SIS), when online purchase are made possible on the web site, each acting as a data controller, is used and where applicable, disclosed to third parties, the measures taken to ensure their confidentiality and security, as well as your choices with respect to the collection and use of your personal data.</p>
					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy1">What personal data is collected on the Web Site?</p>
					<p>When you access and use the Web Site, you may provide certain personal data relating to you, i.e. information that may allow identifying you directly or indirectly.</p>
					<p>Information that may be collected by GROUPE SEB UK Ltd. when you create an account, request a catalogue or information about our products and services, take part in a game or a quiz, or, when applicable by SIS when you make a purchase, may include: your first name and last name, address, phone number, e-mail address, user ID and password associated to your user account, your gender, your date of birth, your interests and preferences.</p>
					<p>Some of the information that you may be asked to provide on the Web Site is mandatory and will be marked as such; some is optional. If you choose not to provide information which is mandatory, you may not be able to access certain parts of the Web Site or we may not be able to respond to requests.</p>
					<p>Moreover, in order to improve the operation of the Web Site and adapt it to your requests, information such as your IP address, your browser type, your operating system, the language used, the pages of the Web Site that you viewed and the order in which you viewed them, the date and duration of your visit, as well as other information that is collected via the use of cookies, may be collected. For more details about the cookies we use, please see our Cookies policy, which is accessible on the Web Site.</p>
					<p>If you are a minor and have provided personal data on the Web Site, you hereby guarantee that you are allowed to do so in accordance with applicable laws, and where required by such applicable laws, that you have obtained the prior authorisation of one of your parents. We hereby disclaim liability should such an authorisation not have been obtained.</p>
					<p>Parents and guardians are advised to foster a responsible and cautious attitude from minors in their care when it comes to disclosing their personal data over the Internet.</p>
					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy2">How is your data used?</p>
					<p>When you create an account on the Web Site, your personal data is processed by GROUPE SEB UK Ltd., which may use it for the following purposes:</p>
					<ul>
						<li>to enable the creation of your user account and respond to your searches and or requests for information concerning the goods or services sold by GROUPE SEB UK Ltd.;</li>
						<li>in order to send you promotional and marketing offers that might be of interest to you, in accordance with your choices in this regard;</li>
						<li>in order to provide you with personalised content and offers on the Web Site;</li>
						<li>in connection with your participation to a game or a quiz, or in any other events available on the Web Site.</li>
					</ul>
					<p>When applicable, when you make a purchase on the Web Site, your personal data will be processed by SIS for managing your order and the delivery.</p>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy3">Protecting your data</p>
					<p>GROUPE SEB UK Ltd. and eventually SIS are committed to protecting the security of the data that you communicate on the Web Site. Appropriate hardware, software and organisational measures have been implemented to protect the data collected through this Web Site, in order to prevent any unauthorised access, use, modification, destruction, disclosure, unlawful processing of your personal data, accidental erasure or loss, as well as any damage linked to such events. However, the security of your data cannot be fully guaranteed.</p>
					<p>The security of your data is ensured in particular:</p>
					<ul>
						<li>by means of password protection;</li>
						<li>by limiting access to your data, only to those persons that are duly empowered (for instance, only the members of staff of GROUPE SEB UK Ltd. and SIS or their employees who need to access same for the purpose indicated above).</li>
					</ul>
					<p>In order to help us protect your data, we recommend that you avoid using an identifier or a password that are too obvious, and regularly change your password and ensure that you never disclose it to anyone.</p>
					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy4">Who has access to your data?</p>
					<p>Your personal data will not be disclosed to any third party, with the exception of:</p>
					<ul>
						<li>affiliates of GROUPE SEB UK Ltd. and SIS</li>
						<li>duly authorized employees of GROUPE SEB UK Ltd. and of SIS and their service providers, in order to enable them to provide services or perform certain tasks on behalf of GROUPE SEB UK Ltd. and of SIS (including sales assistance, market research surveys or customer service, managing accounts, supplies or services provided currently or from time to time, prize draws, competitions and promotions)</li>
						<li>the buyers or potential buyers of these companies, in the event of an acquisition</li>
						<li>in those cases where the law requires or authorises to do so</li>
					</ul>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy5">Your choices in relation to direct marketing</p>
					<p>In accordance with your choices and any applicable laws in this regard, GROUPE SEB UK Ltd. and/or SIS may send you promotional and marketing offers concerning the products and services available on the Web Site, by post, e-mail and SMS, as well as offers concerning goods and services of other brands of Groupe SEB (such as SEB, MOULINEX, ROWENTA, CALOR, KRUPS, LAGOSTINA, ZYLISS) or of its partners.</p>
					<p>You may choose, at any time, to no longer receive such marketing communications, notably by clicking on the unsubscribe link included in a message or by writing to the address mentioned in the last section below.</p>
					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy6">Unsubscribing, deleting or amending your personal data when you have created a user account</p>
					<p>You may unsubscribe from the Web Site at any time by following the procedure described below:</p>
					<ol>
						<li>Connect to the Web Site and identify yourself;</li>
						<li>Click on the “My profile” tab;</li>
						<li>On the right-hand-side of the page, in the box entitled “My account”, click on the “My contact details” link;</li>
						<li>Click on the “Delete my account” link to the right-hand-side of the page;</li>
						<li>Confirm the deletion of your profile.</li>
					</ol>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy7">Third party web sites</p>
					<p>The Web Site may contain links to web sites operated by third parties which are not governed by this Privacy Policy and whose practices in terms of collection and use of personal data are likely to be different from those of the Web Site. As GROUPE SEB UK Ltd. and SIS do not own or operate such web sites, they hereby disclaim liability for the way in which these third party web sites may collect and use your personal data. We recommend that you read carefully the privacy policies of these third party web sites.</p>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy8">Use of your personal data from accounts created on another web site or service of the brand</p>
					<p>If you have created a user account on another web site or service of the brand, we will use your personal data from this account to complete the data of your user account on the Web Site, in order to facilitate your registration on the Web Site and enable you to avoid having to re-enter in information that you may have already provided. If you do not wish your data from other user accounts created on another web site or service of the brand to be used in this way, please contact the customer service by writing to the address mentioned in the last section below.</p>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy9">Modification of the Privacy Policy</p>
					<p>Should the Privacy Policy be modified, the new Privacy Policy will be displayed on this Web Site. We recommend that you check it regularly to learn of any changes that we may bring to it.</p>

					<p className="PrivacyRowentaIntroLead" id="RowentaPrivacy10">Your rights in relation to the processing of your personal data</p>
					<p>In accordance with applicable data protection and privacy laws, you may access, correct, delete and/or object to the processing of your personal data upon legitimate grounds and exercise any other rights to which you are entitled to under applicable laws with respect to the processing of personal data.</p>
					<ol>
						<li>If you have made a purchase on the Web Site, you can exercise your rights before SIS, concerning the data collected and processed to manage your order and delivery, by writing to:<br />
							<p><strong>ROWENTA CONSUMER SERVICE<br />
							Groupe SEB UK Ltd.<br />
							Customer Relations<br />
							Ditton Park<br />
							Riding Court Road<br />
							Datchet<br />
							SL3 9LL<br />
							UNITED KINGDOM<br />
							Tél. : (44) 0345 602 1454
							</strong></p>
							<p>You can also contact the <a href="/consumer-services/contact-us">customer service</a>.</p>
						</li>
						<li>For any other data which is processed by GROUPE SEB UK Ltd. for the creation of your account, to answer your requests, sending you promotional and marketing offers, to personalise the content of the Web Site, and in connection with your participation to events such as quizzes or competitions, you can exercise your rights by writing to:
							<p><strong>ROWENTA  CONSUMER SERVICE<br />
							Groupe SEB UK Ltd.<br />
							Customer Relations<br />
							Ditton Park<br />
							Riding Court Road<br />
							Datchet<br />
							SL3 9LL<br />
							UNITED KINGDOM<br />
							Tél. : (44) 0345 602 1454<br />
							</strong></p>
							<p>You can also contact the <a href="/consumer-services/contact-us">customer service</a>.</p>

						</li>

					</ol>







				</div>
			</Container>
    </div>
  );
};

export default Privacy;








