import React, { useState, Component } from "react";
import BeforeAfterSlider from "react-before-after-slider";
import Carousel from "nuka-carousel";

import "../css/Tutorials.css";

import liftComfortThumbnail1 from "../images/lift-images/liftcomfort-thumbnail1.jpeg";
import liftComfortThumbnail2 from "../images/lift-images/liftcomfort-thumbnail2.jpeg";
import liftComfortThumbnail3 from "../images/lift-images/liftcomfort-thumbnail3.jpeg";
import liftComfortThumbnail4 from "../images/lift-images/liftcomfort-thumbnail4.jpeg";

import BuyOnlineButtonsGroup from "./buyOnlineButtonsGroup";


const width = (typeof window !== "undefined" && window.innerWidth) || 0;
const isMobile = width < 600;
const isTablet = width < 1200 && width > 600;

class BeforeAfter extends Component {
  render() {
    const before = "/beauty-images/antiblemish-before.jpg";
    const after = "/beauty-images/antiblemish-after.jpg";
    return (
      <BeforeAfterSlider
        before={before}
        after={after}
        width={!isMobile ? 920 : 340}
        height={!isMobile ? 360 : 360}
      />
    );
  }
}

const Tutorials = (props) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [video, setVideo] = useState("Ehkp-oNKwKs");

  const toggleVideo = (e) => {
    console.log("clicky click click click");
    console.log(isMobile);
    e.preventDefault();
    if (!e.target.id) {
      setVideo(e.target.parentElement?.id);
    } else {
      setVideo(e.target.id);
    }

    setPlayVideo(playVideo ? false : true);
  };

  return (
    <>
      <section className="antiblemish tutorials-section" id="antiblemish">
        <div className="divider"></div>
        <div className="Tutorials-upper">
          <div></div>
          <h3>Tutorials</h3>
          <div></div>
        </div>
        <div>
          <div className="carouselWrapper tutorial-carousel">
            <Carousel
              // autoplay
              wrapAround
              initialSlideHeight={!isMobile ? 500 : 250}
              height={!isMobile ? 500 : 250}
              heightMode={false}
              renderCenterLeftControls={({ previousSlide }) => (
                <button
                  className="sliderArrows"
                  type="button"
                  onClick={previousSlide}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 42 82"
                    className="leftArrow"
                  >
                    <path
                      d="M41.406 81l-40-40 40-40"
                      fill="none"
                      stroke="#dbb68c"
                      strokeLinecap="round"
                      strokeWidth="2"
                      className="leftArrow"
                    ></path>
                  </svg>
                </button>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <button
                  className="sliderArrows"
                  type="button"
                  onClick={nextSlide}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 42 82"
                    className="rightArrow"
                  >
                    <path
                      d="M1 81l40-40L1 1"
                      fill="none"
                      stroke="#dbb68c"
                      strokeLinecap="round"
                      strokeWidth="2"
                      className="rightArrow"
                    ></path>
                  </svg>
                </button>
              )}
              renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                <div className="circles">
                  <div
                    role="button"
                    className={[
                      currentSlide === 0 ? " current " : "",
                      "circle",
                    ].join(" ")}
                    onClick={() => goToSlide(0)}
                  />
                  <div
                    role="button"
                    className={[
                      currentSlide === 1 ? " current " : "",
                      "circle",
                    ].join(" ")}
                    onClick={() => goToSlide(1)}
                  />
                  <div
                    role="button"
                    className={[
                      currentSlide === 2 ? " current " : "",
                      "circle",
                    ].join(" ")}
                    onClick={() => goToSlide(0)}
                  />
                  <div
                    role="button"
                    className={[
                      currentSlide === 3 ? " current " : "",
                      "circle",
                    ].join(" ")}
                    onClick={() => goToSlide(0)}
                  />
                </div>
              )}
            >
              <div className="Tutorials-slide">
                <figure
                  className="Tutorials-slide-image-container"
                  id="Ehkp-oNKwKs"
                  onClick={(e) => {
                    toggleVideo(e);
                  }}
                >
                  <img
                    alt=""
                    className="Tutorials-image"
                    src={liftComfortThumbnail1}
                  />
                  <figcaption>
                    <strong>Automatic Mode</strong>
                    Face contour
                  </figcaption>
                </figure>
                 
              </div>
              <div className="Tutorials-slide">
                <figure
                  className="Tutorials-slide-image-container"
                  id="jIhpHKDUM6E"
                  onClick={(e) => {
                    toggleVideo(e);
                  }}
                >
                  <img
                    alt=""
                    className="Tutorials-image"
                    src={liftComfortThumbnail2}
                  />
                  <figcaption>
                    <strong>Manual Mode</strong>
                    Face contour
                  </figcaption>
                </figure>
                
              </div>
              <div className="Tutorials-slide">
                <figure
                  className="Tutorials-slide-image-container"
                  onClick={(e) => {
                    toggleVideo(e);
                  }}
                  id="prmB9dKUnN8"
                >
                  <img
                    alt=""
                    className="Tutorials-image"
                    src={liftComfortThumbnail3}
                  />
                  <figcaption>
                    <strong>Global anti-aging care</strong>Face contour
                  </figcaption>
                </figure>
                
              </div>
              <div className="Tutorials-slide">
                <figure
                  className="Tutorials-slide-image-container"
                  onClick={(e) => {
                    toggleVideo(e);
                  }}
                  id="3Le15_D1qIc"
                >
                  <img
                    alt=""
                    className="Tutorials-image"
                    src={liftComfortThumbnail4}
                  />
                  <figcaption>
                    <strong>Younger looking skin</strong>Face contour
                  </figcaption>
                </figure>
                
              </div>
            </Carousel>
            <br /><BuyOnlineButtonsGroup  classes="tutorial-mobile-buttonContainer"/>
          </div>
        </div>
        {playVideo && (
          <section className="Tutorials-video-container" onClick={toggleVideo}>
            <iframe
              className="Tutorials-video"
              width={isMobile ? 800 * 0.4 : isTablet ? 800 * 0.7 : "800"}
              height={isMobile ? 440 * 0.4 : isTablet ? 440 * 0.7 : "440"}
              src={`https://www.youtube.com/embed/${video}`}
            ></iframe>
          </section>
        )}
      </section>
    </>
  );
};

export default Tutorials;
