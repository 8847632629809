import React, { Fragment } from "react";
import "../css/SubCategoryBanner.css";
import Container from "../components/Container";
import { Link } from "react-router-dom";

import steamIronsBanner from "../images/SteamIronsbanner.jpg";
import steamGensBanner from "../images/SteamGensbanner.jpg";
import garmentSteamersBanner from "../images/GarmentSteamersbanner.jpg";
import airPurifiersBanner from "../images/AirPurifiersbanner.jpg";
import humidifiersBanner from "../images/Humidifiersbanner.jpg";
import fansBanner from "../images/Fansbanner.jpg";
import heatersBanner from "../images/Heatersbanner.jpg";
import skinBanner from "../images/range.jpg";

const SubCategoryBanner = (props) => {
	const data = props.data;
	const featured = data.featuredProduct;

	let banner;
	if (data.permalink === "steam-irons") {
		banner = steamIronsBanner;
	}
	if (data.permalink === "steam-gens") {
		banner = steamGensBanner;
	}
	if (data.permalink === "garment-steamers") {
		banner = garmentSteamersBanner;
	}
	if (data.permalink === "air-purifiers") {
		banner = airPurifiersBanner;
	}
	if (data.permalink === "humidifiers") {
		banner = humidifiersBanner;
	}
	if (data.permalink === "fans") {
		banner = fansBanner;
	}
	if (data.permalink === "heaters") {
		banner = heatersBanner;
	}
	if (data.permalink === "skin-care-devices") {
		banner = skinBanner;
	}

	return (
		<section className="banner">
			<Container>
				<div className="banner-content">
					<div className="banner-desc">
						<h1 className="banner-title">{data.title}</h1>
						<p className="banner-excerpt">{data.excerpt}</p>
					</div>
					<div
						className="banner-cta"
						style={{ backgroundImage: `url(${banner})` }}>
							{data.permalink === "skin-care-devices" ? 
							"" 
							: 
							<Fragment>
								<h3 className="banner-featured-title">{featured.title}</h3>
								<p
									className="banner-featured-desc"
									dangerouslySetInnerHTML={{ __html: featured.desc }}
								/>
								<Link
									style={{ fontSize: 16 }}
									className="btn"
									to={`${featured.permalink}`}>
									Discover the product
								</Link>
							</Fragment>
							}
							


						
					</div>
				</div>
			</Container>
		</section>
	);
};

export default SubCategoryBanner;
