import React, { Component } from "react";
import "../css/Nav.css";
import { NavLink } from "react-router-dom";

import Container from "./Container";

export default class Nav extends Component {
	render() {
		return (
			<section className="Nav nav-subcat">
				<Container>
					{this.props.title === "Home Comfort" && (
						<nav>
							<ul>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/home-comfort/air-purifiers"
										title="Air Purifiers">
										Air <br />
										Purifiers
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/home-comfort/humidifiers"
										title="Humidifiers">
										Humidifiers <br />
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/home-comfort/fans"
										title="Fans">
										Fans <br />
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/home-comfort/heaters"
										title="Heaters">
										Heaters <br />
									</NavLink>
								</li>
							</ul>
						</nav>
					)}
					{this.props.title === "Linen Care" && (
						<nav>
							<ul>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/linen-care/steam-irons"
										title="Steam Irons">
										Steam <br />
										Irons
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/linen-care/steam-gens"
										title="Steam Station">
										Steam <br />
										Generators
									</NavLink>
								</li>
								<li>
									<NavLink
										exact
										activeClassName="active"
										to="/linen-care/garment-steamers"
										title="Garment Steamers">
										Garment <br />
										Care
									</NavLink>
								</li>
							</ul>
						</nav>
					)}
				</Container>
			</section>
		);
	}
}
