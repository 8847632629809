import React, { Component, Fragment } from 'react';
import '../css/ConsumerServices.css';
import { Link } from 'react-router-dom';

import Container from './Container';
import SectionTitle from './SectionTitle';

export default class ConsumerServices extends Component {
	render() {
		return(
			<section className="ConsumerServices">
				<Container>
					<SectionTitle topTitle="Consumer" bottomTitle="Services" />
					<div className="Services">
						<Service href="/guarantee" image="https://www.rowentausa.com/medias/garantie.png?context=bWFzdGVyfGltYWdlc3w5MzR8aW1hZ2UvcG5nfGltYWdlcy9oZjYvaGFlLzg5MTU2ODg0ODg5OTAucG5nfDZmN2I1YjdmY2M0MjRhOThjNWUwNTljMTZhMDIyMGRhYzliOWIzMDcwYmE4Yzk5NGRmM2Q3NjdlZDc4M2RmYzM" title="GUARANTEE" description="VIEW THE PRODUCT GUARANTEE CONDITIONS" ></Service>
						<Service href="/repairs" image="https://www.rowentausa.com/medias/sav.png?context=bWFzdGVyfGltYWdlc3wxMDA2fGltYWdlL3BuZ3xpbWFnZXMvaDBlL2g3MC84OTE1Njg4ODgyMjA2LnBuZ3w0N2M2MDVjN2M2ZmZmNzFlZTU1MmQ1NTlmOThjYWIwZGI1NzVlZGI1N2ZhNzgzZjliMTcyZmM4OWE3N2VkN2Nm" title="REPAIR" description="FIND AN OFFICIAL REPAIR CENTER" />
						<Service href="/instructions-for-use" image="https://www.rowentausa.com/medias/manual.png?context=bWFzdGVyfGltYWdlc3w0MTZ8aW1hZ2UvcG5nfGltYWdlcy9oYWEvaGQ4Lzg5MTU2ODg5MTQ5NzQucG5nfDEyM2VlYzYxYzIwYzI1OTk4ODNlYWQxNjMyZWVlNmZjMmY4ZTUzNDY4MmIxZjcyMmQyMTkxMTE2ODgyYzZkMmI" title="INSTRUCTIONS FOR USE" description="FIND THE INSTRUCTIONS FOR USE OF YOUR ROWENTA PRODUCTS" ></Service>
						{/* <Service href="" image="https://www.rowentausa.com/medias/faq.png?context=bWFzdGVyfGltYWdlc3w4MzF8aW1hZ2UvcG5nfGltYWdlcy9oODIvaDFjLzg5MTU2ODg5NDc3NDIucG5nfDNiNzVjOWY4MWQyZGY2Mzc5NzNlNjNkM2M5M2ZmNzVmNWQ2MjVjYWRiMjY0YmFjOWMyMjdiOGM1OTQ1NWUzMmM" title="FREQUENTLY ASKED QUESTIONS" description="GET ANSWERS TO YOUR QUESTIONS" /> */}
						<Service href="/where-to-buy" image="https://www.rowentausa.com/medias/marker.png?context=bWFzdGVyfGltYWdlc3w5MTJ8aW1hZ2UvcG5nfGltYWdlcy9oYmQvaDA2Lzg5MTU2ODg5ODA1MTAucG5nfDQyMTNmNWQ4MmUwNjVlZDJhOTc1NGMxZGM2ZDRiNmE3ZjA1NTMzZDlhYzYzZDFmZmYxNGM1NjQ4YmY0OWFjZGI" title="WHERE TO BUY" description="FIND ON LINE AND IN STORE RETAILERS" />
					</div>
				</Container>
			</section>
		);
	}
}

class Service extends Component {
	render() {
		const Content = () => (
			<Fragment>
				<img className="ServiceIcon" src={this.props.image} alt={this.props.alt}></img>
				<hr className="ServiceHr"></hr>
				<h3 className="ServiceTitle">{this.props.title}</h3>
				<p className="ServiceDescription">{this.props.description}</p>
			</Fragment>
		);

		if (this.props.external) {
			return(
				<a target="_blank"  rel="noopener noreferrer" href={this.props.href} className="Service">
					<Content />
				</a>
			);
		} else {
			return(
				<Link to={this.props.href} className="Service">
					<Content />
				</Link>
			);
		}
	}
}