import React, { Component } from 'react';
import { StyleSheet } from 'aphrodite';
import '../css/TopBannerSimple.css';

import Container from './Container';

import Banner from'../images/guaranteeBanner.jpg'

export default class TopBannerSimple extends Component {
	render() {
		return(
			<section className="TopBannerSimple">
				<Container>
					<div className="TopBannerSimpleContent">
						<img src={Banner} />
						<p>The Rowenta guarantee covers domestic use only. It does not cover professional or commercial use.</p>
						<p><strong>The guarantee period in the United Kingdom and Republic of Ireland for Rowenta appliances sold until 31 December 2016 is 1 year and 2 years on appliances purchased from 1st  January 2017.</strong></p>
					</div>
				</Container>
			</section>
		);
	}
}

const styles = StyleSheet.create({

});

TopBannerSimple.propTypes = {

};