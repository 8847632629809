import React from "react";
import Carousel from "nuka-carousel";

import "../css/FaceContour.css";

import clinicallyProven from "../images/lift-images/lift-comfort-clinically-proven.png";
import liftComfortFaceBenefits from "../images/lift-images/lift-benefits.jpg";
import liftComfortFaceBenefitsMobile from "../images/lift-images/facecontour1.jpg";

import liftComfortFacecontourProduct from "../images/lift-images/liftcomfort-facecontour-product.png";
import liftComfortFaceHowTo1 from "../images/lift-images/liftcomfort-facehowto1.jpeg";
import liftComfortFaceHowTo2 from "../images/lift-images/liftcomfort-facehowto2.jpeg";
import liftComfortFaceHowTo3 from "../images/lift-images/liftcomfort-facehowto3.jpeg";
import liftComfortFaceHowTo4 from "../images/lift-images/liftcomfort-facehowto4.jpeg";
import liftComfortFaceHowTo5 from "../images/lift-images/liftcomfort-facehowto5.jpeg";

import BuyOnlineButtonsGroup from "./buyOnlineButtonsGroup";


const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;



const FaceContour = () => {
  return (
    <section className="antiblemish FaceContour" id="antiblemish">
      <header class="FaceContour-header" id="faceContourAnchor">
        <div className="FaceContour-header-inner">
          <h2>Attachment #1: Face Contour</h2>
          <p>Lift my face contour</p>
        </div>
      </header>

      {!isMobile ? (
        <>
          <div className="clinicallyProven-image-container">
            <img src={clinicallyProven} />
          </div>
          <div>
            <div className="carouselWrapper">
              <Carousel
                // autoplay
                wrapAround
                initialSlideHeight={630}
                height={630}
                heightMode={false}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={previousSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="leftArrow"
                    >
                      <path
                        d="M41.406 81l-40-40 40-40"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="leftArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    className="sliderArrows"
                    type="button"
                    onClick={nextSlide}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 42 82"
                      className="rightArrow"
                    >
                      <path
                        d="M1 81l40-40L1 1"
                        fill="none"
                        stroke="#dbb68c"
                        strokeLinecap="round"
                        strokeWidth="2"
                        className="rightArrow"
                      ></path>
                    </svg>
                  </button>
                )}
                renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                  <div className="circles">
                    <div
                      role="button"
                      className={[
                        currentSlide === 0 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(0)}
                    />
                    <div
                      role="button"
                      className={[
                        currentSlide === 1 ? " current " : "",
                        "circle",
                      ].join(" ")}
                      onClick={() => goToSlide(1)}
                    />
                  </div>
                )}
              >
                <div className="FaceContour-slide FaceContour-slide1">
                  <div className="FaceContour-slide-header">
                    <h3>Benefits for your skin</h3>
                  </div>
                  <div className="FaceContour-slide1-inner">
                    <figure className="FaceContour-image-container FaceContour-image1">
                      <img
                        className="FaceContour-image"
                        src={liftComfortFaceBenefits}
                      />
                    </figure>
                    <figure className="FaceContour-image-container FaceContour-image2">
                      <img
                        className="FaceContour-product-image"
                        src={liftComfortFacecontourProduct}
                      />
                      <figcaption>
                        <h4>
                          <strong>4</strong>
                          <span>
                            Clinically proven <br />
                            anti-ageing benefits<sup>**</sup>
                          </span>
                        </h4>
                        <p>
                          Skin radiance
                          <br />
                          Evenness of skin tone
                          <br />
                          Glow
                          <br />
                          Firmness
                          <br />
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                  <p className="FaceContour-footnote disclaimer"><i><sup>**</sup> 2 months clinical study – 34 volunteers – France – 2019</i></p>
                </div>
                <div className="FaceContour-slide discover-slide2">
                  <div className="FaceContour-slide-header">
                    <h3>How to use</h3>
                    <p className="FaceContour-slide2-subtitle">
                      <strong>2X3 MIN</strong> skincare ritual for a more
                      youthful, sublimated skin <br />
                      <strong>4 times</strong> a week morning or evening
                    </p>
                  </div>
                  <div className="FaceContour-slide2-inner">
                    <figure>
                      <img src={liftComfortFaceHowTo1} />
                      <figcaption>
                        <strong>1</strong>{" "}
                        <span>
                          For more comfort, apply your usual anti-ageing cream
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img
                        class="figure-image-two"
                        src={liftComfortFaceHowTo2}
                      />
                      <figcaption class="figcaption-two">
                        <strong>2</strong>{" "}
                        <span>
                          Choose your mode: Auto or Manu. Click on the right
                          arrow
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={liftComfortFaceHowTo3} />
                      <figcaption>
                        <strong>3</strong>{" "}
                        <span>
                          Massage the right side of your face from bottom
                          upwards (3 min recommended)
                        </span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={liftComfortFaceHowTo4} />
                      <figcaption>
                        <strong>4</strong>{" "}
                        <span>Change side and massage (3 min recommended)</span>
                      </figcaption>
                    </figure>
                    <figure>
                      <img
                        class="figure-image-five"
                        src={liftComfortFaceHowTo5}
                      />
                      <figcaption class="figcaption-five">
                        <strong>5</strong>{" "}
                        <span>Admire! Your face contour is lifted!</span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </Carousel>
              <br /><BuyOnlineButtonsGroup  />
            </div>
            
          </div>
        </>
      ) : (
        <>
          <div>
            {" "}
            <div className="FaceContour-slide-header">
              <img
                className="FaceContour-product-image"
                src={liftComfortFacecontourProduct}
              />
              <h3>Benefits for your skin</h3>

              <div className="clinicallyProven-image-container-mobile">
                <img
                  className="clinically-proven-FaceContour-moblie-one"
                  src={clinicallyProven}
                />
              </div>
            </div>
            <figure className="FaceContour-mobile-image-container FaceContour-image1">
              <img
                className="FaceContour-image"
                src={liftComfortFaceBenefitsMobile}
              />

              <figcaption>
                <h4>
                  <strong>4</strong>
                  <span>
                    Clinically proven <br />
                    anti-ageing benefits<sup>**</sup>
                  </span>
                </h4>
                <p>
                  Skin radiance
                  <br />
                  Evenness of skin tone
                  <br />
                  Glow
                  <br />
                  Firmness
                  <br />
                </p>
              </figcaption>
            </figure>
            <br /><BuyOnlineButtonsGroup  />
            <p className="FaceContour-footnote">
              <strong>
                <i>
                  ** 2 months clinical study - 34 volunteers - France - 2019
                </i>
              </strong>
            </p>
            <div class="divider faceContour-divider"></div>
            {/* <div></div> */}
            <div className="FaceContour-slide-header">
              <div></div>
              <div>
                <h3>How to use</h3>
                <p className="FaceContour-slide2-subtitle">
                  <strong>2X3 MIN</strong> skincare ritual for a{" "}
                  {isMobile && <br />} more youthful, sublimated skin <br />
                  <strong>4 times</strong> a week morning or evening
                </p>
              </div>
              <div className="clinicallyProven-image-container-mobile">
                <img src={clinicallyProven} />
              </div>
            </div>
            <div className="carouselWrapper">
              <div className="FaceContour-mobile-slide2-inner">
                <Carousel
                  autoplay
                  wrapAround
                  // initialSlideHeight={590}
                  // height={590}
                  heightMode={'max'}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      className="sliderArrows"
                      type="button"
                      onClick={previousSlide}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 42 82"
                        className="leftArrow"
                      >
                        <path
                          d="M41.406 81l-40-40 40-40"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="4"
                          className="leftArrow"
                        ></path>
                      </svg>
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      className="sliderArrows"
                      type="button"
                      onClick={nextSlide}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 42 82"
                        className="rightArrow"
                      >
                        <path
                          d="M1 81l40-40L1 1"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="4"
                          className="rightArrow"
                        ></path>
                      </svg>
                    </button>
                  )}
                  renderBottomCenterControls={({ goToSlide, currentSlide }) => (
                    <div className="circles">
                      <div
                        role="button"
                        className={[
                          currentSlide === 0 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(0)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 1 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(1)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 2 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(2)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 3 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(3)}
                      />
                      <div
                        role="button"
                        className={[
                          currentSlide === 4 ? " current " : "",
                          "circle",
                        ].join(" ")}
                        onClick={() => goToSlide(4)}
                      />
                    </div>
                  )}
                >
                  <div>
                    <img src={liftComfortFaceHowTo1} alt="" />
                    <figcaption>
                      <strong>1</strong>{" "}
                      <span>
                        For more comfort, apply your usual anti-ageing cream
                      </span>
                    </figcaption>
                  </div>
                  <div>
                    <img src={liftComfortFaceHowTo2} alt="" />
                    <figcaption>
                      <strong>2</strong>
                      <span>
                        Choose the speed that suits you. Use the brush with
                        circular motion
                      </span>
                    </figcaption>
                  </div>
                  <div>
                    <img src={liftComfortFaceHowTo3} alt="" />
                    <figcaption>
                      <strong>3</strong>{" "}
                      <span>
                        Massage the right side of your face from bottom upwards
                        (3 min recommended)
                      </span>
                    </figcaption>
                  </div>
                  <div>
                    <img src={liftComfortFaceHowTo4} alt="" />
                    <figcaption>
                      <strong>4</strong>{" "}
                      <span>Change side and massage (3 min recommended)</span>
                    </figcaption>
                  </div>
                  <div>
                    <img src={liftComfortFaceHowTo5} alt="" />
                    <figcaption class="figcaption-five">
                      <strong>5</strong>{" "}
                      <span>Admire! Your face contour is lifted!</span>
                    </figcaption>
                  </div>
                </Carousel>
                
              </div>
              <br /><BuyOnlineButtonsGroup  />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default FaceContour;
