import React, { Fragment, useState } from 'react';

import '../css/EyeFeatures.css'
import ss1 from '../images/noflaws-eyemassager-performance.png';

const Features = () => {
	const isMobile = (typeof window !== "undefined" && window.innerWidth) < 768;
	const Product = ({ features, dots }) => {
		const [active, setActive] = useState(1);		

		return (
			<div className="antifatigue-slide4-content">
				<div className="antifatigue-slide4-content-left">
						<img src={ss1} />
						{dots.map(d => {
							return (
								<button
									tabIndex={0}
									key={d.id}
									className={["circle-button", active === d.id ? "active" : ""].join(" ")}
									onClick={() => {setActive(d.id);}}
									style={{
										left: d.left,
										right: d.right,
										top: d.top,
										bottom: d.bottom,
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">  <path d="M12.9 23.6h-1.7V12.8H.4v-1.5h10.8V.4h1.7v10.8h10.7v1.5H12.9v10.9z"></path></svg>
								</button>
							);
						})}
				</div>

				<div className="antifatigue-slide4-content-right">
					<div className="features">
						{features.map(f => {
							return (
								<div
									tabIndex="-1"
									key={f.id}
									className={["feature", active === f.id ? "active" : ""].join(" ")}>	
										<div className="featureDesc">{f.desc}</div>
								</div>
							);
						})}
					</div>
				</div>
				

				
			</div >
		);
	}

	return (
		<section >
					<Product
						dots={[		
							{ id: 1, top: "0", left: `${isMobile ? "-44px" : "143px"}` },
							{ id: 2, left: `${isMobile ? "-42px" : "148px" }`, top: `${isMobile ? "88px" : "110px" }` },
							{ id: 3, right: `${isMobile? "-17px" : "137px"}` , bottom: `${isMobile ? "17px" : "41px"}` },
						]}
						features={[
							{
								id: 1,
								desc: <Fragment><p>Blue LED light : <strong>know for its purifying properties</strong></p></Fragment>,
							},
							{
								id: 2,
								desc: <Fragment><p>2 speeds of gentle vibrations, to <strong>reduce signs of fatigue and boosts the efficiency</strong> of your eye cream</p></Fragment>,							
							},
							{
								id: 3,
								desc: <Fragment><p><strong>Compact size</strong>, nomad travel-friendly and easy to use</p></Fragment>,								
							},
							
							
							
						]}
					/>


		</section>
	);
}

export default Features;