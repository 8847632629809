import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../css/Glow.css";
import "../css/Radiance.css";
import "../css/Flaws.css";
import { Redirect } from "react-router-dom";
import HeaderBeauty from "../components/HeaderBeauty";
import DiscoverVideoSectionGlow from "../components/DiscoverVideoSectionGlow";
import Radiance from "../components/Radiance";
import Radiance2 from "../components/Radiance2";
import Radiance4 from "../components/Radiance4";

import FAQToggleFacial from "../components/FAQToggleFacial";

import poster from "../images/facial-images/noflaws-poster.jpg";
import eyemassager from "../images/facial-images/product-noflaws-eye-massager.png";
import brush from "../images/facial-images/product-noflaws-brush.png";

import liftPoster from "../images/facial-images/liftcomfort-poster.jpg";
import liftEyemassager from "../images/facial-images/product-liftcomfort-eyecontour.png";
import liftBrush from "../images/facial-images/product-liftcomfort-head.png";

const Glow = (props) => {
  if (window.location.pathname.includes("/personal-care/skin-care-devices/")) {
    return <Redirect to="/glow-skin" />;
  }
  const width = (typeof window !== "undefined" && window.innerWidth) || 0;
  const isMobile = width < 768;

  return (
    <div id="glow">
      <Helmet>
        <meta
          name="title"
          content="Glow Skin Range - My Beauty Routine Skincare Devices - Rowenta UK"
        />
        <meta
          name="description"
          content="The Glow Skin range, part of the My Beauty Routine collection of skincare devices by Rowenta. Bring Power to Your Skin."
        />
      </Helmet>

      <HeaderBeauty />
      <DiscoverVideoSectionGlow />
      <Radiance />
      <Radiance2 />
      <Radiance4 />
      <FAQToggleFacial />
      <section className="no-flaws-banner">
        <h2>
          No Flaws
          <br />
          Collection
        </h2>
        <p>For a flawless skin tone</p>
        <Link
          to="/no-flaws"
          className="care-btn facial-care-btn"
          title="Discover"
        >
          Discover
        </Link>
        <div className="figure">
          <img src={poster} className="collection-poster" />
          <img src={eyemassager} className="collection-eye-massager" />
          <img src={brush} className="collection-brush" />
        </div>
      </section>

      <section className="lift-banner">
        <figure>
          <img src={liftPoster} className="lift-poster" />
          <img src={liftEyemassager} className="lift-eye-massager" />
          <img src={liftBrush} className="lift-brush" />
        </figure>

        <h2>
          Lift
          <br />
          Collection
        </h2>
        <p>For firmer skin and lifted contour</p>
        <Link to="/lift" className="care-btn facial-care-btn" title="Discover">
          Discover
        </Link>
      </section>
    </div>
  );
};

export default Glow;
