import React, { useState } from "react";

import "../css/LiftCountourMassager.css";

import clinicallyProven from "../images/lift-images/lift-comfort-clinically-proven.png";
import liftFaceMassager from "../images/lift-images/lift-face-massager.jpeg";
import BuyOnlineButtonsGroup from "./buyOnlineButtonsGroup";

const LiftCountourMassager = (props) => {
  const [playVideo, setPlayVideo] = useState(false);

  const toggleVideo = () => {
    setPlayVideo(playVideo ? false : true);
  };
  const width = (typeof window !== "undefined" && window.innerWidth) || 0;
  const isMobile = width < 768;
  const isTablet = width < 1200 && width > 768;

  return (
    <>      
      <article>
        <div className="lcm-upper">
          <div></div>
          <h3>Discover</h3>
          <div className="lcm-image-container">
            <img src={clinicallyProven} />
          </div>
        </div>

        <div className="lcm-main">
          <div></div>

          <div className="main-image-container" onClick={toggleVideo}>
            <img src={liftFaceMassager} className="video-thumbnail" />
          </div>

          <BuyOnlineButtonsGroup  classes="negativeMargin"/>
          <div></div>
        </div>
      </article>
      <header class="lcm-header">
        <h2>Lift Contour{isMobile && <br />} Massager</h2>
        <p>
          Face &amp; eye global care
          <br />
          Anti-ageing ritual
        </p>
      </header>
      {playVideo && (
        <section className="lcm-video-container" onClick={toggleVideo}>
          <iframe
            className="lcm-video"
            width={isMobile ? 800 * 0.4 : isTablet ? 800 * 0.9 : "800"}
            height={isMobile ? 440 * 0.4 : isTablet ? 440 * 0.9 : "440"}
            src="https://www.youtube.com/embed/I3apo9rSbGQ"
          ></iframe>
        </section>
      )}
    </>
  );
};

export default LiftCountourMassager;
