import React from "react";

import BuyOnlineButton from "./buyOnlineButton";
import "../css/BuyOnlineButtonsGroup.css";

import argos from '../images/argos.png';
import very from '../images/very.png';


const BuyOnlineButtonsGroup = ({classes}) => {
  return (

      <div className={["buttonContainer", classes].join(" ")}>        
        <div className="retailers">
          <div>
            <img src={argos} alt="Argos" />
          </div>
          <BuyOnlineButton href={"https://www.argos.co.uk/search/rowenta-my-beauty/brands:rowenta/"} />
        </div>
        <div className="retailers">
          <div>
            <img src={very} alt="Very" />
          </div>
          <BuyOnlineButton href={"https://www.very.co.uk/beauty/rowenta/e/b/100159,200,4294936499.end"} />
        </div>
      </div>
  );
};

export default BuyOnlineButtonsGroup;
