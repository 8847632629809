import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../components/Container";

const ProductRegistration = () => {
  const [iframeHeight, setIframeHeight] = useState(2000);

  useEffect(() => {
    // Listen for incoming payment response
    window.addEventListener("message", function (event) {
      if (event.origin === "https://gsuk-prodreg.netlify.app") {
        if (event.data.applicationHeight) {
          setIframeHeight(event.data.applicationHeight);
        }
      }
    });
  }, []);

  return (
    <div className="product-registration">
      <Helmet title="Rowenta | Product Registration" />
      <Container>
        <iframe
          src="https://gsuk-prodreg.netlify.app?brand=Rowenta"
          frameborder="0"
          scrolling="no"
          style={{ height: iframeHeight, width: "100%", marginTop: 15 }}
        />
      </Container>
    </div>
  );
};

export default ProductRegistration;
