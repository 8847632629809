import React, { useState } from "react";

import '../css/FAQFacial.css';

const Accordion = () => {
	const [active, setActive] = useState();
	

	const KeyValuesAccordionContent = ({ index, title, desc, open }) => {
		return (
			<div className="keyvalue_accordion_inner">
				<div  className={['keyvalue_accordion', 'keyvalue_accordion_item', open ? open : ""].join(" ")}>
					
						<button onClick={() => setActive(index)} style={{display: open ? "none" : "flex"}} className="keyvalue_accordion_header">
							<h4 dangerouslySetInnerHTML={{ __html: title }} />
							<div className="close"><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
								
							</span></div>
						</button>
					
						<div style={{display: open ? "block" : "none"}} className='keyvalue_accordion_item'>
							<div className='keyvalue_accordion_content'>
								<div className='keyvalue_accordion_content_left'>
									<h4 dangerouslySetInnerHTML={{ __html: title }} />
									<p dangerouslySetInnerHTML={{ __html: desc }} />
								</div>
								<div className='keyvalue_accordion_content_right'>
									<div onClick={() => setActive(0)} className='close'><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
										</span>
									</div>
								</div>
							</div>
						</div>
				
				</div>
			</div>
		);
	}

	return (
		<section id="keyvalue">
			<div className='keyvalue_accordion'>
				<KeyValuesAccordionContent
					index={1}
					open={active === 1}	
                    title="<span>1/</span> How can I use the Anti-ageing Eye Massager?"				
					desc="<p>For a better result, we advise you to use the Eye Massager daily 1 minute per eye.  </p>"					
				/>
				<KeyValuesAccordionContent
					index={2}
					open={active === 2}
					title="<span>2/</span> Can I use it with my usual eye cream?"
					desc="<p>Yes, the Eye Massager can be used alone or with your usual eye cream. It will even boost its benefits! </p>"					
				/>
				<KeyValuesAccordionContent
					index={3}
					open={active === 3}
					title="<span>3/</span> How much time do I have to apply the Eye Massager on the desired zone?"
					desc="<p>Using the appliance 1 minute per eye a day you can benefit from an anti-ageing effect. </p>"					
				/>
				<KeyValuesAccordionContent
					index={4}
					open={active === 4}
					title="<span>4</span> What are the differences between the two modes?"
					desc="<p>The Eye Massager combines two technologies for a global care: Vibrations stimulate lymphatic system and bloodstream which unclog and reduce eye bags, and Red LED light known for its anti-ageing properties boosts collagen and smooths fine lines. </p>"					
				/>
				<KeyValuesAccordionContent
					index={5}
					open={active === 5}
					title="<span>5</span> How the LED lights work on my skin?"
					desc="<p>It is scientifically proven that LED (Light-emitting diode) perform on the skin thanks to the wavelengths they produce. Each LED colour is absorbed by a certain type of cells at a different depth and with a precise function.</p>
					<ul>
					  <li>Blue | 460 nm: purifying action, works on the epidermis</li>
					  <li>Red | 645 nm: anti-ageing action, stimulates collagen and elastin</li>
					</ul>"					
				/>
				



				
			</div>
		</section>
	);
}

export default Accordion;
