import React, { Component } from 'react';
import { StyleSheet } from 'aphrodite';
import '../css/Breadcrumb.css';

import Container from './Container'

export default class Breadcrumb extends Component {
  render() {
		return(
			<section className="Breadcrumb">
      	<Container>
					<nav>
						<ul>
            	<li></li>
			 				<li></li>
			 				<li></li>
		 				</ul>
			  	</nav>
				</Container>
			</section>
		);
	}
}

const styles = StyleSheet.create({

});

Breadcrumb.propTypes = {

}