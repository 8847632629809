import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import DiscoverVideoSectionFlaws from "../components/DiscoverVideoSectionFlaws";
import FAQToggle from "../components/FAQToggle";
import Antiblemish from "../components/Antiblemish";
import Antifatigue from "../components/Antifatigue";
import HeaderBeauty from "../components/HeaderBeauty";

import "../css/Flaws.css";
import { Redirect } from "react-router-dom";
import radiancebooster from "../images/radiancebooster-poster.jpg";
import eyemassager from "../images/glowskin-product-eyemassager.png";
import brush from "../images/glowskin-product-brush.png";

import liftPoster from "../images/facial-images/liftcomfort-poster.jpg";
import liftEyemassager from "../images/facial-images/product-liftcomfort-eyecontour.png";
import liftBrush from "../images/facial-images/product-liftcomfort-head.png";

const Flaws = (props) => {
  if (window.location.pathname.includes("/personal-care/skin-care-devices/")) {
    return <Redirect to="/no-flaws" />;
  }
  const width = (typeof window !== "undefined" && window.innerWidth) || 0;
  const isMobile = width < 768;

  return (
    <div id="flaws">
      <Helmet>
        <meta
          name="title"
          content="No Flaws Range - My Beauty Routine Skincare Devices - Rowenta UK"
        />
        <meta
          name="description"
          content="The No Flaws range, part of the My Beauty Routine collection of skincare devices by Rowenta. Bring Power to Your Skin. "
        />
      </Helmet>
      <HeaderBeauty />
      <DiscoverVideoSectionFlaws />
      <Antiblemish />
      <Antifatigue />
      <FAQToggle />

      <section className="glowskin-banner">
        <figure>
          <img src={radiancebooster} className="collection-poster" />
          <img src={eyemassager} className="collection-eye-massager" />
          <img src={brush} className="collection-brush" />
        </figure>

        <h2>
          Glow Skin
          <br /> Collection
        </h2>
        <p>For a younger looking skin</p>
        <Link to="/glow-skin" className="care-btn" title="Discover">
          Discover
        </Link>
      </section>

      <section className="lift-banner">
        <figure>
          <img src={liftPoster} className="lift-poster" />
          <img src={liftEyemassager} className="lift-eye-massager" />
          <img src={liftBrush} className="lift-brush" />
        </figure>

        <h2>
          Lift
          <br />
          Collection
        </h2>
        <p>For firmer skin and lifted contour</p>
        <Link to="/lift" className="care-btn facial-care-btn" title="Discover">
          Discover
        </Link>
      </section>
    </div>
  );
};

export default Flaws;
