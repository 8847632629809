import React, { useState } from "react";

import '../css/FAQFacial.css';

const Accordion = () => {
	const [active, setActive] = useState();
	

	const KeyValuesAccordionContent = ({ index, title, desc, open }) => {
		return (
			<div className="keyvalue_accordion_inner">
				<div  className={['keyvalue_accordion', 'keyvalue_accordion_item', open ? open : ""].join(" ")}>
					
						<button onClick={() => setActive(index)} style={{display: open ? "none" : "flex"}} className="keyvalue_accordion_header">
							<h4 dangerouslySetInnerHTML={{ __html: title }} />
							<div className="close"><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
								
							</span></div>
						</button>
					
						<div style={{display: open ? "block" : "none"}} className='keyvalue_accordion_item'>
							<div className='keyvalue_accordion_content'>
								<div className='keyvalue_accordion_content_left'>
									<h4 dangerouslySetInnerHTML={{ __html: title }} />
									<p dangerouslySetInnerHTML={{ __html: desc }} />
								</div>
								<div className='keyvalue_accordion_content_right'>
									<div onClick={() => setActive(0)} className='close'><span className={[open ? 'open_cross' : 'close_cross'].join(" ")}>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 82" className="arrowButton"><path d="M41.406 81l-40-40 40-40" fill="none" stroke="" strokeLinecap="round" strokeWidth="6" className="arrowButton"></path></svg>
										</span>
									</div>
								</div>
							</div>
						</div>
				
				</div>
			</div>
		);
	}

	return (
		<section id="keyvalue">
			<div className='keyvalue_accordion'>
				<KeyValuesAccordionContent
					index={1}
					open={active === 1}	
                    title="<span>1/</span> What does massage do to your skin?"				
					desc="<p>Facial massage stimulates blood circulation and helps your skin glow. <br>
                    By massaging your face regularly, your skin will produce more collagen and elastin helping the skin looks younger for longer.</p>"					
				/>
				<KeyValuesAccordionContent
					index={2}
					open={active === 2}
					title="<span>2/</span> How and when should I use my Youth Facial Massager?"
					desc=" <p>We advise to use it 2min/day, 4times/week, in the morning or at night, after applying your usual cream to get the best results. <br>
                    Use the Youth Facial Massager avoiding sensitive areas (lips, eyes) and creating movement from the bottom up.</p>"					
				/>
				<KeyValuesAccordionContent
					index={3}
					open={active === 3}
					title="<span>3/</span> What kind of product should I use with my Youth Facial Massager?"
					desc="<p>You can use your usual Day Cream or Night cream, or even your serum. Your Youth Facial Massager helps your daily anti-ageing cream to be better absorbed into the skin.</p>"					
				/>
				<KeyValuesAccordionContent
					index={4}
					open={active === 4}
					title="<span>4</span> How long does it take to see results?"
					desc="<p>Comforting and relaxing effect can be felt instantly. After just one month, your skin will be beautiful: toned, plumped and so radiant! </p>"					
				/>
				<KeyValuesAccordionContent
					index={5}
					open={active === 5}
					title="<span>5</span> How can I clean my product?"
					desc="<p>Your Youth Facial Massager is fully waterproof. To clean it you can rinse it under your tap.</p>"					
				/>
				<KeyValuesAccordionContent
					index={6}
					open={active === 6}
					title="<span>6</span> Can I use the product under water?"
					desc="<p>Yes your Youth Facial Massager is fully waterproof. </p>"					
				/>



				
			</div>
		</section>
	);
}

export default Accordion;
